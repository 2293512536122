.root {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.wrapper {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 0px;
}
.brand {
  display: block;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
  align-self: auto;
}
.items {
  flex-direction: column;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
}
.items > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.items > :global(.__wab_flex-container) > *,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items > :global(.__wab_flex-container) > picture > img,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10.758px;
}
.serviceItem:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
}
