.root {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 43.034px);
  height: calc(100% + 43.034px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 43.034px;
}
.frame9 {
  flex-direction: row;
  background: var(--token-0kv5OK8yc);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  cursor: pointer;
  min-width: 0;
  padding: 16px 0px;
}
.frame9 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.frame9 > :global(.__wab_flex-container) > *,
.frame9 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame9 > :global(.__wab_flex-container) > picture > img,
.frame9
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 10px;
}
.frame9disabled {
  opacity: 0.5;
}
.freeBox {
  display: flex;
  position: relative;
  height: auto;
}
.slotTargetChildren {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
  letter-spacing: 0px;
  text-transform: uppercase;
}
