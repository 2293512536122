.root {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  max-width: 100%;
  background: #ffffff;
  position: relative;
  min-width: 0;
  padding: 0px;
}
