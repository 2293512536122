.root {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--token-BRpR8JzSS);
  min-width: 0;
  min-height: 0;
  padding: 24px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.frame5 {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  z-index: 2;
  min-width: 0;
}
.frame5 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame5 > :global(.__wab_flex-container) > *,
.frame5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame5 > :global(.__wab_flex-container) > picture > img,
.frame5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.wrapper {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.title__sjGxR:global(.__wab_instance) {
  width: 100%;
  min-width: 0;
}
.textorder__sr6SGuaTvu {
  display: none;
}
.textorderCompleted__sr6SGmFCkN {
  display: none;
}
.textorderSearching__sr6SgDDnx {
  display: none;
}
.textorderSelect__sr6SGmxEBu {
  display: none;
}
.ordersTitle {
  height: auto;
}
.ordersTitleorder {
  display: none;
}
.ordersTitleorderCompleted {
  display: none;
}
.ordersTitleorderSearching {
  display: none;
}
.orderTitle {
  display: none;
}
.orderTitleorder {
  display: none;
}
.orderTitleorderCompleted {
  display: none;
}
.orderTitleWrapper {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.orderTitleWrapperorderCompleted {
  display: flex;
}
.orderTitleWrapperorderSearching {
  display: flex;
}
.frame17 {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.frame17 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.frame17 > :global(.__wab_flex-container) > *,
.frame17 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame17 > :global(.__wab_flex-container) > picture > img,
.frame17
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 4px;
}
.text__yhziF {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.textorderCompleted__yhziFmFCkN {
  padding-top: 0px;
}
.skiName {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.skiNameorderSearching {
  display: none;
}
.number {
  display: block;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.numberorderSearching {
  padding-left: 0px;
}
.putToText {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  padding: 24px 0px;
}
.putToText > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.putToText > :global(.__wab_flex-container) > *,
.putToText > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.putToText > :global(.__wab_flex-container) > picture > img,
.putToText
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.frame13 {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.frame13 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.frame13 > :global(.__wab_flex-container) > *,
.frame13 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame13 > :global(.__wab_flex-container) > picture > img,
.frame13
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.text__xb1W9 {
  display: block;
  font-size: 16.137px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.frame14 {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.frame14 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.frame14 > :global(.__wab_flex-container) > *,
.frame14 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame14 > :global(.__wab_flex-container) > picture > img,
.frame14
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.containerNumber {
  display: block;
  font-size: 40px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.orderInfo {
  flex-direction: column;
  width: auto;
  height: auto;
  display: none;
}
.orderInfo > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.orderInfo > :global(.__wab_flex-container) > *,
.orderInfo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.orderInfo > :global(.__wab_flex-container) > picture > img,
.orderInfo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.orderInfodone {
  display: flex;
}
.theOrder {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.orderTxt {
  display: block;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.text__g2Bps {
  display: block;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.preloader {
  plasmic-display-none: false;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.preloaderpreloading {
  display: flex;
}
.preloaderorderSearching {
  display: flex;
}
.freeBox___4Ev9 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxorderSelect___4Ev9MxEBu {
  display: flex;
}
.filters:global(.__wab_instance) {
  max-width: 100%;
  display: none;
}
.filtersorderSelect:global(.__wab_instance) {
  display: flex;
}
.orderInfoWrapper {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
}
.orderInfoWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.orderInfoWrapper > :global(.__wab_flex-container) > *,
.orderInfoWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.orderInfoWrapper > :global(.__wab_flex-container) > picture > img,
.orderInfoWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.freeBox__zLJyY {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.freeBox__zLJyY > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__zLJyY > :global(.__wab_flex-container) > *,
.freeBox__zLJyY > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__zLJyY > :global(.__wab_flex-container) > picture > img,
.freeBox__zLJyY
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.text__xjUpI {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.freeBox__nuzJ {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.freeBox__nuzJ > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.freeBox__nuzJ > :global(.__wab_flex-container) > *,
.freeBox__nuzJ > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__nuzJ > :global(.__wab_flex-container) > picture > img,
.freeBox__nuzJ
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.orderDate {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.servicesWrapper {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.servicesWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.servicesWrapper > :global(.__wab_flex-container) > *,
.servicesWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.servicesWrapper > :global(.__wab_flex-container) > picture > img,
.servicesWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.serviceTitle {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.serviceTitle > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.serviceTitle > :global(.__wab_flex-container) > *,
.serviceTitle > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.serviceTitle > :global(.__wab_flex-container) > picture > img,
.serviceTitle
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.text___55Diq {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.serviceItems {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.serviceItems > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.serviceItems > :global(.__wab_flex-container) > *,
.serviceItems > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.serviceItems > :global(.__wab_flex-container) > picture > img,
.serviceItems
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.text__gdi4G {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.text__pegar {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.commentWrapper {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.commentWrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.commentWrapper > :global(.__wab_flex-container) > *,
.commentWrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.commentWrapper > :global(.__wab_flex-container) > picture > img,
.commentWrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.commentTitle {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.commentTitle > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.commentTitle > :global(.__wab_flex-container) > *,
.commentTitle > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.commentTitle > :global(.__wab_flex-container) > picture > img,
.commentTitle
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.text__hs19W {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.serviceItems2 {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.serviceItems2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.serviceItems2 > :global(.__wab_flex-container) > *,
.serviceItems2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.serviceItems2 > :global(.__wab_flex-container) > picture > img,
.serviceItems2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.comment {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
  padding-right: 0px;
}
.form {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.form > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.formloading {
  display: flex;
}
.formpreloading {
  display: none;
}
.formorderSelect {
  height: 100%;
  min-height: 0;
}
.formorderSelect > :global(.__wab_flex-container) {
  min-height: 0;
}
.complete:global(.__wab_instance) {
  display: none;
}
.completeorderCompleted:global(.__wab_instance) {
  display: flex;
}
.text__x5WV {
  padding-right: 0px;
}
.scan:global(.__wab_instance) {
  display: none;
}
.scanorder:global(.__wab_instance) {
  display: none;
}
.scanorderCompleted:global(.__wab_instance) {
  display: none;
}
.scanenterCode:global(.__wab_instance) {
  display: none;
}
.completeOrder:global(.__wab_instance) {
  plasmic-display-none: false;
}
.completeOrderorder:global(.__wab_instance) {
  display: flex;
}
.completeOrderorderCompleted:global(.__wab_instance) {
  display: none;
}
.completeOrderenterCode:global(.__wab_instance) {
  display: none;
}
.selectPlace:global(.__wab_instance) {
  plasmic-display-none: false;
}
.selectPlaceorder:global(.__wab_instance) {
  display: none;
}
.selectPlaceorderCompleted:global(.__wab_instance) {
  display: none;
}
.textorderCompleted___9PfFmmFCkN {
  padding-right: 0px;
  height: auto;
}
.cancelOrderorderCompleted:global(.__wab_instance) {
  display: none;
}
.textorderCompleted__hj6OKmFCkN {
  padding-right: 0px;
}
.backToOrderorder:global(.__wab_instance) {
  display: none;
}
.backToOrderorderCompleted:global(.__wab_instance) {
  display: flex;
}
.textorderCompleted__kjd2RmFCkN {
  padding-right: 0px;
}
.enterCodeBtnorder:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnscanning:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnloading:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnorderCompleted:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnenterCode:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnorderSearching:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnorderError:global(.__wab_instance) {
  display: none;
}
.enterCodeBtnorderSelect:global(.__wab_instance) {
  display: none;
}
.scanBarcodeorder:global(.__wab_instance) {
  display: none;
}
.scanBarcodescanning:global(.__wab_instance) {
  display: none;
}
.scanBarcodeloading:global(.__wab_instance) {
  display: none;
}
.scanBarcodeorderCompleted:global(.__wab_instance) {
  display: none;
}
.scanBarcodeenterCode:global(.__wab_instance) {
  display: none;
}
.scanBarcodeorderSearching:global(.__wab_instance) {
  display: none;
}
.scanBarcodeorderError:global(.__wab_instance) {
  display: none;
}
.scanBarcodeorderSelect:global(.__wab_instance) {
  display: none;
}
.text___9GQ4 {
  height: auto;
}
@media (min-width: 768px) {
  .text___9GQ4 {
    height: auto;
  }
}
.showOrderList:global(.__wab_instance) {
  display: flex;
}
.showOrderListorder:global(.__wab_instance) {
  display: none;
}
.showOrderListscanning:global(.__wab_instance) {
  display: none;
}
.showOrderListloading:global(.__wab_instance) {
  display: none;
}
.showOrderListorderCompleted:global(.__wab_instance) {
  display: none;
}
.showOrderListenterCode:global(.__wab_instance) {
  display: none;
}
.showOrderListorderSearching:global(.__wab_instance) {
  display: none;
}
.showOrderListorderError:global(.__wab_instance) {
  display: none;
}
.showOrderListorderSelect:global(.__wab_instance) {
  display: none;
}
@media (min-width: 768px) {
  .text__ao25Y {
    height: auto;
  }
}
.orders {
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.orders > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.ordersorderSelect {
  height: 100%;
  min-height: 0;
  display: flex;
}
.ordersorderSelect > :global(.__wab_flex-container) {
  justify-content: flex-start;
  align-items: center;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.ordersorderSelect > :global(.__wab_flex-container) > *,
.ordersorderSelect > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.ordersorderSelect > :global(.__wab_flex-container) > picture > img,
.ordersorderSelect
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: 20px;
}
.code:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  margin-bottom: 24px;
  display: none;
}
.codeenterCode:global(.__wab_instance) {
  display: flex;
}
.codeorderError:global(.__wab_instance) {
  display: flex;
}
.svg__ssMxN {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__mqpk {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.codeErrorWrapper {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 12px 8px;
}
.codeErrorWrapperenterCode {
  display: none;
}
.freeBox__hbsBs {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  display: none;
  border-radius: 4px;
  padding: 4px 24px;
}
.freeBoxenterCode__hbsBszyhI4 {
  display: flex;
}
.codeErrrorTxt {
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-0kv5OK8yc);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  min-width: 0;
  display: none;
}
.codeErrrorTxtenterCode {
  display: none;
}
.codeStatusTxt {
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-5JSkJ0nFu);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  min-width: 0;
}
.errorTxt {
  plasmic-display-none: false;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  min-width: 0;
}
.submitCodeorder:global(.__wab_instance) {
  display: none;
}
.submitCodeorderCompleted:global(.__wab_instance) {
  display: none;
}
.submitCodeorderSearching:global(.__wab_instance) {
  display: flex;
}
.submitCodeorderError:global(.__wab_instance) {
  display: flex;
}
.cancelCodeorder:global(.__wab_instance) {
  display: none;
}
.cancelCodeorderCompleted:global(.__wab_instance) {
  display: none;
}
.cancelCodeorderError:global(.__wab_instance) {
  display: flex;
}
.text__snrhd {
  padding-right: 0px;
}
.preloaderContainer {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.preloaderContainerloading {
  display: flex;
}
.scanner:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 60vh;
  flex-shrink: 0;
  display: none;
}
.scannerscanning:global(.__wab_instance) {
  display: flex;
}
.freeBox___0Xhqx {
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  top: auto;
  bottom: 0px;
  height: 100px;
  min-width: 0;
  flex-shrink: 0;
  display: none;
  padding: 8px;
}
.freeBoxmenuOpen___0XhqXrstHg {
  left: auto;
}
.freeBoxuserMenu___0Xhqxiyx4X {
  display: flex;
}
.actions {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 400px;
  position: relative;
  top: auto;
  z-index: 1;
  bottom: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
.actionsmenuOpen {
  max-width: 400px;
}
.actionsuserMenu {
  display: flex;
}
.logout:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.fade {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  left: 0px;
  top: -16px;
  background: #21202240;
  backdrop-filter: blur(7px);
  z-index: 1;
  flex-shrink: 0;
  display: none;
  -webkit-backdrop-filter: blur(7px);
  padding: 0px;
}
.fadeuserMenu {
  display: block;
}
