@import url(https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Open+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B0%2C800&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plasmic_hiihtocenter_plasmic_tokens__1wzb3 {
  --token-0kv5OK8yc: #b9042d;
  --plasmic-token-primary: var(--token-0kv5OK8yc);
  --token-5JSkJ0nFu: #616161;
  --plasmic-token-unnamed-style-token: var(--token-5JSkJ0nFu);
  --token-BRpR8JzSS: #231f20;
  --plasmic-token-background: var(--token-BRpR8JzSS);
}

.plasmic_hiihtocenter_plasmic_default_styles__2dXvR {
  --mixin-X00bFdoWre_font-family: "Inter", sans-serif;
  --mixin-X00bFdoWre_font-size: 16px;
  --mixin-X00bFdoWre_font-weight: 400;
  --mixin-X00bFdoWre_font-style: normal;
  --mixin-X00bFdoWre_color: #535353;
  --mixin-X00bFdoWre_text-align: left;
  --mixin-X00bFdoWre_text-transform: none;
  --mixin-X00bFdoWre_line-height: 1.5;
  --mixin-X00bFdoWre_letter-spacing: normal;
  --mixin-X00bFdoWre_white-space: pre-wrap;
  --mixin-X00bFdoWre_user-select: text;
  --mixin-X00bFdoWre_text-decoration-line: none;
  --mixin-X00bFdoWre_text-overflow: clip;
  --mixin-vWv7grl6YD_font-family: "Inter", sans-serif;
  --mixin-vWv7grl6YD_color: #000000;
  --mixin-vWv7grl6YD_font-size: 24px;
  --mixin-vWv7grl6YD_font-weight: 600;
  --mixin-vWv7grl6YD_letter-spacing: -0.5px;
  --mixin-vWv7grl6YD_line-height: 1.3;
  --mixin-vWv7grl6YD_white-space: pre-wrap;
  --mixin-iNbojl0nhI_font-family: "Inter", sans-serif;
  --mixin-iNbojl0nhI_color: #000000;
  --mixin-iNbojl0nhI_font-size: 20px;
  --mixin-iNbojl0nhI_font-weight: 600;
  --mixin-iNbojl0nhI_letter-spacing: -0.3px;
  --mixin-iNbojl0nhI_line-height: 1.5;
  --mixin-iNbojl0nhI_white-space: pre-wrap;
  --mixin-wy-2o8AKq-_font-family: "Inter", sans-serif;
  --mixin-wy-2o8AKq-_color: #000000;
  --mixin-wy-2o8AKq-_font-size: 16px;
  --mixin-wy-2o8AKq-_font-weight: 600;
  --mixin-wy-2o8AKq-_line-height: 1.5;
  --mixin-wy-2o8AKq-_white-space: pre-wrap;
  --mixin-tVe6SYYSop_color: #0070f3;
  --mixin-tVe6SYYSop_white-space: pre-wrap;
  --mixin-ZnKXQ8mijo_border-left-color: #dddddd;
  --mixin-ZnKXQ8mijo_border-left-style: solid;
  --mixin-ZnKXQ8mijo_border-left-width: 3px;
  --mixin-ZnKXQ8mijo_color: #888888;
  --mixin-ZnKXQ8mijo_padding-left: 10px;
  --mixin-ZnKXQ8mijo_white-space: pre-wrap;
  --mixin-tiKRlyKiB_font-family: "Inter", sans-serif;
  --mixin-tiKRlyKiB_color: #000000;
  --mixin-tiKRlyKiB_font-size: 72px;
  --mixin-tiKRlyKiB_font-weight: 900;
  --mixin-tiKRlyKiB_letter-spacing: -4px;
  --mixin-tiKRlyKiB_line-height: 1;
  --mixin-tiKRlyKiB_white-space: pre-wrap;
  --mixin-70DPORDir-_font-family: "Inter", sans-serif;
  --mixin-70DPORDir-_color: #000000;
  --mixin-70DPORDir-_font-size: 48px;
  --mixin-70DPORDir-_font-weight: 700;
  --mixin-70DPORDir-_letter-spacing: -1px;
  --mixin-70DPORDir-_line-height: 1.1;
  --mixin-70DPORDir-_white-space: pre-wrap;
  --mixin-VLfLpuOl-T_font-family: "Inter", sans-serif;
  --mixin-VLfLpuOl-T_color: #000000;
  --mixin-VLfLpuOl-T_font-size: 32px;
  --mixin-VLfLpuOl-T_font-weight: 600;
  --mixin-VLfLpuOl-T_letter-spacing: -0.8px;
  --mixin-VLfLpuOl-T_line-height: 1.2;
  --mixin-VLfLpuOl-T_white-space: pre-wrap;
  --mixin-aSDICzgaZE_color: #3291ff;
  --mixin-aSDICzgaZE_white-space: pre-wrap;
  --mixin-yipuD8O4E8_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-yipuD8O4E8_border-bottom-color: #dddddd;
  --mixin-yipuD8O4E8_border-bottom-style: solid;
  --mixin-yipuD8O4E8_border-bottom-width: 1px;
  --mixin-yipuD8O4E8_border-left-color: #dddddd;
  --mixin-yipuD8O4E8_border-left-style: solid;
  --mixin-yipuD8O4E8_border-left-width: 1px;
  --mixin-yipuD8O4E8_border-right-color: #dddddd;
  --mixin-yipuD8O4E8_border-right-style: solid;
  --mixin-yipuD8O4E8_border-right-width: 1px;
  --mixin-yipuD8O4E8_border-top-color: #dddddd;
  --mixin-yipuD8O4E8_border-top-style: solid;
  --mixin-yipuD8O4E8_border-top-width: 1px;
  --mixin-yipuD8O4E8_border-bottom-left-radius: 3px;
  --mixin-yipuD8O4E8_border-bottom-right-radius: 3px;
  --mixin-yipuD8O4E8_border-top-left-radius: 3px;
  --mixin-yipuD8O4E8_border-top-right-radius: 3px;
  --mixin-yipuD8O4E8_font-family: "Inconsolata";
  --mixin-yipuD8O4E8_padding-bottom: 1px;
  --mixin-yipuD8O4E8_padding-left: 4px;
  --mixin-yipuD8O4E8_padding-right: 4px;
  --mixin-yipuD8O4E8_padding-top: 1px;
  --mixin-yipuD8O4E8_white-space: pre-wrap;
  --mixin-RoQWLylCHO_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-RoQWLylCHO_border-bottom-color: #dddddd;
  --mixin-RoQWLylCHO_border-bottom-style: solid;
  --mixin-RoQWLylCHO_border-bottom-width: 1px;
  --mixin-RoQWLylCHO_border-left-color: #dddddd;
  --mixin-RoQWLylCHO_border-left-style: solid;
  --mixin-RoQWLylCHO_border-left-width: 1px;
  --mixin-RoQWLylCHO_border-right-color: #dddddd;
  --mixin-RoQWLylCHO_border-right-style: solid;
  --mixin-RoQWLylCHO_border-right-width: 1px;
  --mixin-RoQWLylCHO_border-top-color: #dddddd;
  --mixin-RoQWLylCHO_border-top-style: solid;
  --mixin-RoQWLylCHO_border-top-width: 1px;
  --mixin-RoQWLylCHO_border-bottom-left-radius: 3px;
  --mixin-RoQWLylCHO_border-bottom-right-radius: 3px;
  --mixin-RoQWLylCHO_border-top-left-radius: 3px;
  --mixin-RoQWLylCHO_border-top-right-radius: 3px;
  --mixin-RoQWLylCHO_font-family: "Inconsolata";
  --mixin-RoQWLylCHO_padding-bottom: 3px;
  --mixin-RoQWLylCHO_padding-left: 6px;
  --mixin-RoQWLylCHO_padding-right: 6px;
  --mixin-RoQWLylCHO_padding-top: 3px;
  --mixin-RoQWLylCHO_white-space: pre-wrap;
  --mixin-uWGc3Qwcpc_display: flex;
  --mixin-uWGc3Qwcpc_flex-direction: column;
  --mixin-uWGc3Qwcpc_align-items: stretch;
  --mixin-uWGc3Qwcpc_justify-content: flex-start;
  --mixin-uWGc3Qwcpc_list-style-position: outside;
  --mixin-uWGc3Qwcpc_padding-left: 40px;
  --mixin-uWGc3Qwcpc_position: relative;
  --mixin-uWGc3Qwcpc_list-style-type: decimal;
  --mixin-uWGc3Qwcpc_white-space: pre-wrap;
  --mixin-ac2l2P-4rs_display: flex;
  --mixin-ac2l2P-4rs_flex-direction: column;
  --mixin-ac2l2P-4rs_align-items: stretch;
  --mixin-ac2l2P-4rs_justify-content: flex-start;
  --mixin-ac2l2P-4rs_list-style-position: outside;
  --mixin-ac2l2P-4rs_padding-left: 40px;
  --mixin-ac2l2P-4rs_position: relative;
  --mixin-ac2l2P-4rs_list-style-type: disc;
  --mixin-ac2l2P-4rs_white-space: pre-wrap;
}

:where(.plasmic_hiihtocenter_all__3wMXI) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.plasmic_hiihtocenter_img__1e1pj) {
  display: inline-block;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK img) {
  white-space: inherit;
}

:where(.plasmic_hiihtocenter_li__1zbMs) {
  display: list-item;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK li) {
  white-space: inherit;
}

:where(.plasmic_hiihtocenter_span__37dlW) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_hiihtocenter_input__2SNwy) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_hiihtocenter_textarea__qWGLK) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.plasmic_hiihtocenter_button__3T_x2) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.plasmic_hiihtocenter_code__3d_SK) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_hiihtocenter_pre__lKRnZ) {
  font-family: inherit;
  line-height: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.plasmic_hiihtocenter_p__3gV12) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.plasmic_hiihtocenter_h1__2INq7) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_hiihtocenter_h2__rCP2z) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_hiihtocenter_h3__1Baeb) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_hiihtocenter_h4__One27) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_hiihtocenter_h5__30ZGN) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_hiihtocenter_h6__-r6td) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.plasmic_hiihtocenter_address__Mzpjm) {
  font-style: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.plasmic_hiihtocenter_a__3G09V) {
  color: inherit;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK a) {
  white-space: inherit;
  color: inherit;
}

:where(.plasmic_hiihtocenter_ol__28g3f) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_hiihtocenter_ul__KGSZR) {
  list-style-type: none;
  padding: 0;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.plasmic_hiihtocenter_select__1uImi) {
  padding: 2px 6px;
}
:where(.plasmic_hiihtocenter___wab_expr_html_text__1q6xK select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_hiihtocenter_plasmic_default__component_wrapper__1gUqZ {
  display: grid;
}
.plasmic_hiihtocenter_plasmic_default__inline__19Syb {
  display: inline;
}
.plasmic_hiihtocenter_plasmic_page_wrapper__peN3I {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_hiihtocenter_plasmic_page_wrapper__peN3I > * {
  height: auto !important;
}
.plasmic_hiihtocenter___wab_expr_html_text__1q6xK {
  white-space: normal;
}
.plasmic_hiihtocenter_root_reset__25LON {
  font-family: var(--mixin-X00bFdoWre_font-family);
  font-size: var(--mixin-X00bFdoWre_font-size);
  font-weight: var(--mixin-X00bFdoWre_font-weight);
  font-style: var(--mixin-X00bFdoWre_font-style);
  color: var(--mixin-X00bFdoWre_color);
  text-align: var(--mixin-X00bFdoWre_text-align);
  text-transform: var(--mixin-X00bFdoWre_text-transform);
  line-height: var(--mixin-X00bFdoWre_line-height);
  letter-spacing: var(--mixin-X00bFdoWre_letter-spacing);
  white-space: var(--mixin-X00bFdoWre_white-space);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__h4__21RLm),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_h4__One27),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text h4),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__h4__21RLm) {
  font-family: var(--mixin-vWv7grl6YD_font-family);
  color: var(--mixin-vWv7grl6YD_color);
  font-size: var(--mixin-vWv7grl6YD_font-size);
  font-weight: var(--mixin-vWv7grl6YD_font-weight);
  letter-spacing: var(--mixin-vWv7grl6YD_letter-spacing);
  line-height: var(--mixin-vWv7grl6YD_line-height);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__h5__312hN),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_h5__30ZGN),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text h5),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__h5__312hN) {
  font-family: var(--mixin-iNbojl0nhI_font-family);
  color: var(--mixin-iNbojl0nhI_color);
  font-size: var(--mixin-iNbojl0nhI_font-size);
  font-weight: var(--mixin-iNbojl0nhI_font-weight);
  letter-spacing: var(--mixin-iNbojl0nhI_letter-spacing);
  line-height: var(--mixin-iNbojl0nhI_line-height);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__h6__1DWd7),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_h6__-r6td),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text h6),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__h6__1DWd7) {
  font-family: var(--mixin-wy-2o8AKq-_font-family);
  color: var(--mixin-wy-2o8AKq-_color);
  font-size: var(--mixin-wy-2o8AKq-_font-size);
  font-weight: var(--mixin-wy-2o8AKq-_font-weight);
  line-height: var(--mixin-wy-2o8AKq-_line-height);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__a__1h-8b),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_a__3G09V),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text a),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__a__1h-8b) {
  color: var(--mixin-tVe6SYYSop_color);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__blockquote__28o4Q),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_blockquote__ofoR6),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text blockquote),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__blockquote__28o4Q) {
  color: var(--mixin-ZnKXQ8mijo_color);
  padding-left: var(--mixin-ZnKXQ8mijo_padding-left);
  border-left: var(--mixin-ZnKXQ8mijo_border-left-width)
    var(--mixin-ZnKXQ8mijo_border-left-style)
    var(--mixin-ZnKXQ8mijo_border-left-color);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__h1__XXAy8),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_h1__2INq7),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text h1),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__h1__XXAy8) {
  font-family: var(--mixin-tiKRlyKiB_font-family);
  color: var(--mixin-tiKRlyKiB_color);
  font-size: var(--mixin-tiKRlyKiB_font-size);
  font-weight: var(--mixin-tiKRlyKiB_font-weight);
  letter-spacing: var(--mixin-tiKRlyKiB_letter-spacing);
  line-height: var(--mixin-tiKRlyKiB_line-height);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__h2__CekKI),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_h2__rCP2z),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text h2),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__h2__CekKI) {
  font-family: var(--mixin-70DPORDir-_font-family);
  color: var(--mixin-70DPORDir-_color);
  font-size: var(--mixin-70DPORDir-_font-size);
  font-weight: var(--mixin-70DPORDir-_font-weight);
  letter-spacing: var(--mixin-70DPORDir-_letter-spacing);
  line-height: var(--mixin-70DPORDir-_line-height);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__h3__Fq5Tp),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_h3__1Baeb),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text h3),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__h3__Fq5Tp) {
  font-family: var(--mixin-VLfLpuOl-T_font-family);
  color: var(--mixin-VLfLpuOl-T_color);
  font-size: var(--mixin-VLfLpuOl-T_font-size);
  font-weight: var(--mixin-VLfLpuOl-T_font-weight);
  letter-spacing: var(--mixin-VLfLpuOl-T_letter-spacing);
  line-height: var(--mixin-VLfLpuOl-T_line-height);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__a__1h-8b:hover),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_a__3G09V:hover),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text a:hover),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__a__1h-8b:hover) {
  color: var(--mixin-aSDICzgaZE_color);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__code__2P0kH),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_code__3d_SK),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text code),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__code__2P0kH) {
  background: #f8f8f8;
  font-family: var(--mixin-yipuD8O4E8_font-family);
  border-radius: var(--mixin-yipuD8O4E8_border-top-left-radius)
    var(--mixin-yipuD8O4E8_border-top-right-radius)
    var(--mixin-yipuD8O4E8_border-bottom-right-radius)
    var(--mixin-yipuD8O4E8_border-bottom-left-radius);
  padding: var(--mixin-yipuD8O4E8_padding-top)
    var(--mixin-yipuD8O4E8_padding-right) var(--mixin-yipuD8O4E8_padding-bottom)
    var(--mixin-yipuD8O4E8_padding-left);
  border-top: var(--mixin-yipuD8O4E8_border-top-width)
    var(--mixin-yipuD8O4E8_border-top-style)
    var(--mixin-yipuD8O4E8_border-top-color);
  border-right: var(--mixin-yipuD8O4E8_border-right-width)
    var(--mixin-yipuD8O4E8_border-right-style)
    var(--mixin-yipuD8O4E8_border-right-color);
  border-bottom: var(--mixin-yipuD8O4E8_border-bottom-width)
    var(--mixin-yipuD8O4E8_border-bottom-style)
    var(--mixin-yipuD8O4E8_border-bottom-color);
  border-left: var(--mixin-yipuD8O4E8_border-left-width)
    var(--mixin-yipuD8O4E8_border-left-style)
    var(--mixin-yipuD8O4E8_border-left-color);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__pre__1OSxp),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_pre__lKRnZ),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text pre),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__pre__1OSxp) {
  background: #f8f8f8;
  font-family: var(--mixin-RoQWLylCHO_font-family);
  border-radius: var(--mixin-RoQWLylCHO_border-top-left-radius)
    var(--mixin-RoQWLylCHO_border-top-right-radius)
    var(--mixin-RoQWLylCHO_border-bottom-right-radius)
    var(--mixin-RoQWLylCHO_border-bottom-left-radius);
  padding: var(--mixin-RoQWLylCHO_padding-top)
    var(--mixin-RoQWLylCHO_padding-right) var(--mixin-RoQWLylCHO_padding-bottom)
    var(--mixin-RoQWLylCHO_padding-left);
  border-top: var(--mixin-RoQWLylCHO_border-top-width)
    var(--mixin-RoQWLylCHO_border-top-style)
    var(--mixin-RoQWLylCHO_border-top-color);
  border-right: var(--mixin-RoQWLylCHO_border-right-width)
    var(--mixin-RoQWLylCHO_border-right-style)
    var(--mixin-RoQWLylCHO_border-right-color);
  border-bottom: var(--mixin-RoQWLylCHO_border-bottom-width)
    var(--mixin-RoQWLylCHO_border-bottom-style)
    var(--mixin-RoQWLylCHO_border-bottom-color);
  border-left: var(--mixin-RoQWLylCHO_border-left-width)
    var(--mixin-RoQWLylCHO_border-left-style)
    var(--mixin-RoQWLylCHO_border-left-color);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__ol__3es54),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_ol__28g3f),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text ol),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__ol__3es54) {
  display: var(--mixin-uWGc3Qwcpc_display);
  flex-direction: var(--mixin-uWGc3Qwcpc_flex-direction);
  align-items: var(--mixin-uWGc3Qwcpc_align-items);
  justify-content: var(--mixin-uWGc3Qwcpc_justify-content);
  list-style-position: var(--mixin-uWGc3Qwcpc_list-style-position);
  padding-left: var(--mixin-uWGc3Qwcpc_padding-left);
  position: var(--mixin-uWGc3Qwcpc_position);
  list-style-type: var(--mixin-uWGc3Qwcpc_list-style-type);
  flex-column-gap: var(--mixin-uWGc3Qwcpc_flex-column-gap);
}

:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_plasmic_default__ul__1T0nV),
:where(.plasmic_hiihtocenter_root_reset__25LON .plasmic_hiihtocenter_ul__KGSZR),
:where(.plasmic_hiihtocenter_root_reset__25LON .__wab_expr_html_text ul),
:where(.plasmic_hiihtocenter_root_reset__25LON.plasmic_hiihtocenter_plasmic_default__ul__1T0nV) {
  display: var(--mixin-ac2l2P-4rs_display);
  flex-direction: var(--mixin-ac2l2P-4rs_flex-direction);
  align-items: var(--mixin-ac2l2P-4rs_align-items);
  justify-content: var(--mixin-ac2l2P-4rs_justify-content);
  list-style-position: var(--mixin-ac2l2P-4rs_list-style-position);
  padding-left: var(--mixin-ac2l2P-4rs_padding-left);
  position: var(--mixin-ac2l2P-4rs_position);
  list-style-type: var(--mixin-ac2l2P-4rs_list-style-type);
  flex-column-gap: var(--mixin-ac2l2P-4rs_flex-column-gap);
}

.PlasmicServiceItem_root__3Yg8W {
  flex-direction: row;
  height: auto;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.PlasmicServiceItem_root__3Yg8W > .__wab_flex-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - 11px);
  width: calc(100% + 11px);
}
.PlasmicServiceItem_root__3Yg8W > .__wab_flex-container > *,
.PlasmicServiceItem_root__3Yg8W > .__wab_flex-container > .__wab_slot > *,
.PlasmicServiceItem_root__3Yg8W > .__wab_flex-container > picture > img,
.PlasmicServiceItem_root__3Yg8W > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 11px;
}
.PlasmicServiceItem_name__3IR1J {
  color: #ffffff;
}
.PlasmicServiceItem_price__2euH9 {
  color: #ffffff;
}

.PlasmicOrderItem_root__2AQU1 {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.PlasmicOrderItem_root__2AQU1 > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicOrderItem_root__2AQU1 > .__wab_flex-container > *,
.PlasmicOrderItem_root__2AQU1 > .__wab_flex-container > .__wab_slot > *,
.PlasmicOrderItem_root__2AQU1 > .__wab_flex-container > picture > img,
.PlasmicOrderItem_root__2AQU1 > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 12px;
}
.PlasmicOrderItem_wrapper__2Ahlo {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.PlasmicOrderItem_wrapper__2Ahlo > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 0px);
  height: calc(100% + 0px);
}
.PlasmicOrderItem_wrapper__2Ahlo > .__wab_flex-container > *,
.PlasmicOrderItem_wrapper__2Ahlo > .__wab_flex-container > .__wab_slot > *,
.PlasmicOrderItem_wrapper__2Ahlo > .__wab_flex-container > picture > img,
.PlasmicOrderItem_wrapper__2Ahlo
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 0px;
}
.PlasmicOrderItem_brand__25GXu {
  display: block;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
  align-self: auto;
}
.PlasmicOrderItem_items__nBy-B {
  flex-direction: column;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
}
.PlasmicOrderItem_items__nBy-B > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicOrderItem_items__nBy-B > .__wab_flex-container > *,
.PlasmicOrderItem_items__nBy-B > .__wab_flex-container > .__wab_slot > *,
.PlasmicOrderItem_items__nBy-B > .__wab_flex-container > picture > img,
.PlasmicOrderItem_items__nBy-B > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 10.758px;
}
.PlasmicOrderItem_serviceItem__1clZ4.__wab_instance {
  position: relative;
  align-self: stretch;
}

.PlasmicBookingSmsInformation_root__31e5h {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-BRpR8JzSS);
  overflow: auto;
  min-width: 0;
}
.PlasmicBookingSmsInformation_freeBox__clLf9__B_kgz {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  padding: 8px;
}
.PlasmicBookingSmsInformation_frame5__jDs-H {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  overflow: hidden;
}
.PlasmicBookingSmsInformation_frame5__jDs-H > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.PlasmicBookingSmsInformation_frame5__jDs-H > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_frame5__jDs-H > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_frame5__jDs-H > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_frame5__jDs-H
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10px;
}
.PlasmicBookingSmsInformation_img__2xhCo {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.PlasmicBookingSmsInformation_img__2xhCo > picture > img {
  object-fit: cover;
}
.PlasmicBookingSmsInformation_img__2xhCo > .__wab_img-spacer > img {
  object-fit: cover;
}
.PlasmicBookingSmsInformation_freeBox___3JCkx__3Sn4O {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 800px;
  min-width: 0;
  min-height: 0;
  padding: 24px;
}
.PlasmicBookingSmsInformation_freeBox__fVjif__1aqGs {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.PlasmicBookingSmsInformation_freeBox__fVjif__1aqGs > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicBookingSmsInformation_freeBox__fVjif__1aqGs > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_freeBox__fVjif__1aqGs > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_freeBox__fVjif__1aqGs > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_freeBox__fVjif__1aqGs
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicBookingSmsInformation_frame33__14igK {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  min-width: 0;
}
.PlasmicBookingSmsInformation_frame33__14igK > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.PlasmicBookingSmsInformation_frame33__14igK > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_frame33__14igK > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_frame33__14igK > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_frame33__14igK
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10px;
}
.PlasmicBookingSmsInformation_text___3FOoe__3l20- {
  display: block;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicBookingSmsInformation_freeBox__gkogO__3PAkT {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  background: #ffffff;
  min-width: 0;
  padding: 0px;
}
.PlasmicBookingSmsInformation_svg__dnghY__2EaCi {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 100%;
  height: 1px;
  display: block;
  min-width: 0;
  flex-shrink: 0;
}
.PlasmicBookingSmsInformation_freeBox__vlX6U__1Atpf {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 6px;
  padding: 20px;
  margin: 12px 0px;
}
.PlasmicBookingSmsInformation_image__3egtR {
  object-fit: contain;
  max-width: 70%;
  width: 150px;
  height: 100px;
  flex-shrink: 0;
}
.PlasmicBookingSmsInformation_image__3egtR > picture > img {
  object-fit: contain;
}
.PlasmicBookingSmsInformation_image__3egtR > .__wab_img-spacer > img {
  object-fit: contain;
}
.PlasmicBookingSmsInformation_numberTxt__2_e66 {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 0px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-weight: 700;
  min-width: 0;
}
.PlasmicBookingSmsInformation_orderInfo__2E_rK {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  min-width: 0;
}
.PlasmicBookingSmsInformation_orderInfo__2E_rK > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.PlasmicBookingSmsInformation_orderInfo__2E_rK > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_orderInfo__2E_rK > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_orderInfo__2E_rK > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_orderInfo__2E_rK
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 24px;
}
.PlasmicBookingSmsInformation_frame15__2QF7N {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.PlasmicBookingSmsInformation_frame15__2QF7N > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicBookingSmsInformation_frame15__2QF7N > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_frame15__2QF7N > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_frame15__2QF7N > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_frame15__2QF7N
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicBookingSmsInformation_text__kg0VX__1_DhQ {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicBookingSmsInformation_frame14__3cURQ {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.PlasmicBookingSmsInformation_frame14__3cURQ > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicBookingSmsInformation_frame14__3cURQ > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_frame14__3cURQ > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_frame14__3cURQ > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_frame14__3cURQ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicBookingSmsInformation_date__3X16w {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicBookingSmsInformation_items__2HPkB {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.PlasmicBookingSmsInformation_items__2HPkB > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.PlasmicBookingSmsInformation_items__2HPkB > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_items__2HPkB > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_items__2HPkB > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_items__2HPkB > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 30px;
}
.PlasmicBookingSmsInformation_freeBox___12Yab__2fhpy {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  background: #ffffff;
  min-width: 0;
  padding: 0px;
}
.PlasmicBookingSmsInformation_svg__rurPp__2WL8L {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 100%;
  height: 1px;
  display: block;
  min-width: 0;
  flex-shrink: 0;
}
.PlasmicBookingSmsInformation_servicesWrapper2__3UKCe {
  flex-direction: row;
  height: auto;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.PlasmicBookingSmsInformation_servicesWrapper2__3UKCe > .__wab_flex-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.PlasmicBookingSmsInformation_servicesWrapper2__3UKCe > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_servicesWrapper2__3UKCe > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_servicesWrapper2__3UKCe > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_servicesWrapper2__3UKCe
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.PlasmicBookingSmsInformation_serviceTitle2__3AASG {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicBookingSmsInformation_serviceTitle2__3AASG > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicBookingSmsInformation_serviceTitle2__3AASG > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_serviceTitle2__3AASG > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_serviceTitle2__3AASG > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_serviceTitle2__3AASG
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicBookingSmsInformation_text__amSEs__2S9RS {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicBookingSmsInformation_items2__27LoR {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicBookingSmsInformation_items2__27LoR > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicBookingSmsInformation_items2__27LoR > .__wab_flex-container > *,
.PlasmicBookingSmsInformation_items2__27LoR > .__wab_flex-container > .__wab_slot > *,
.PlasmicBookingSmsInformation_items2__27LoR > .__wab_flex-container > picture > img,
.PlasmicBookingSmsInformation_items2__27LoR
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicBookingSmsInformation_totalPrice__7rLCD {
  display: block;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
  font-weight: 700;
}

.PlasmicLine_root__2wCFt {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 1px;
  max-width: 100%;
  background: #ffffff;
  position: relative;
  min-width: 0;
  padding: 0px;
}

.PlasmicTitle_root__3JH2G {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.PlasmicTitle_root__3JH2G > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicTitle_root__3JH2G > .__wab_flex-container > *,
.PlasmicTitle_root__3JH2G > .__wab_flex-container > .__wab_slot > *,
.PlasmicTitle_root__3JH2G > .__wab_flex-container > picture > img,
.PlasmicTitle_root__3JH2G > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 10.758px;
}
.PlasmicTitle_signIn__3PDyz {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}
.PlasmicTitle_slotTargetChildren__cE6Ks {
  font-family: "Open Sans", sans-serif;
  font-size: 16.137px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  line-height: 1;
  letter-spacing: 0px;
  text-align: center;
}
.PlasmicTitle_line__1V3Gs.__wab_instance {
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
}

.PlasmicTextInput_root__3GLW_ {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  min-width: 0;
  border-radius: 0px;
  padding: 12px;
  border: 1px solid #dbdbd7;
}
.PlasmicTextInput_rootcolor_dark__1fzsG {
  background: #232320;
  border-color: #717069;
}
.PlasmicTextInput_root__3GLW_:hover {
  border-color: #c8c7c1;
}
.PlasmicTextInput_root__3GLW_:focus-within {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
  border-radius: 6px;
}
.PlasmicTextInput_root___focusVisibleWithin__2yuW5 {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
  border-radius: 6px;
}
.PlasmicTextInput_startIconContainer__1zKED {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-right: 8px;
  display: none;
}
.PlasmicTextInput_startIconContainershowStartIcon__ALWqF {
  display: flex;
}
.PlasmicTextInput_root__3GLW_ .PlasmicTextInput_startIconContainer___focusVisibleWithin__3BBJs {
  outline: none;
}
.PlasmicTextInput_slotTargetStartIconshowStartIcon__3bGts {
  color: #90908c;
}
.PlasmicTextInput_slotTargetStartIconcolor_dark__3UMZb {
  color: #ffffff;
}
.PlasmicTextInput_svg__rhRA__Aq3ap {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}
.PlasmicTextInput_input__2eYCG {
  width: 100%;
  left: auto;
  top: auto;
  background: #ffffff00;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #535353;
  min-width: 0;
  padding: 0px;
  border-width: 0px;
}
.PlasmicTextInput_inputisDisabled__HQyad {
  cursor: not-allowed;
}
.PlasmicTextInput_inputcolor_dark__38cM4 {
  color: #ffffff;
}
.PlasmicTextInput_root__3GLW_:focus-within .PlasmicTextInput_input__2eYCG {
  outline: none;
}
.PlasmicTextInput_root__3GLW_ .PlasmicTextInput_input___focusVisibleWithin__3E6Tl {
  outline: none;
}
.PlasmicTextInput_root__3GLW_ .PlasmicTextInput_input__2eYCG:focus {
  outline: none;
}
.PlasmicTextInput_root__3GLW_:focus-within .PlasmicTextInput_input__2eYCG:focus {
  outline: none;
}
.PlasmicTextInput_root__3GLW_ .PlasmicTextInput_input__2eYCG::-webkit-input-placeholder {
  color: #706f6c;
}
.PlasmicTextInput_root__3GLW_ .PlasmicTextInput_input__2eYCG::placeholder {
  color: #706f6c;
}
.PlasmicTextInput_rootcolor_dark__1fzsG .PlasmicTextInput_inputcolor_dark__38cM4::-webkit-input-placeholder {
  color: #c8c7c1;
}
.PlasmicTextInput_rootcolor_dark__1fzsG .PlasmicTextInput_inputcolor_dark__38cM4::placeholder {
  color: #c8c7c1;
}
.PlasmicTextInput_root__3GLW_:focus-within .PlasmicTextInput_input__2eYCG::-webkit-input-placeholder {
  outline: none;
}
.PlasmicTextInput_root__3GLW_:focus-within .PlasmicTextInput_input__2eYCG::placeholder {
  outline: none;
}
.PlasmicTextInput_endIconContainer__1z2yB {
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  left: auto;
  top: auto;
  margin-left: 8px;
  display: none;
}
.PlasmicTextInput_endIconContainershowEndIcon__3PooP {
  display: flex;
}
.PlasmicTextInput_slotTargetEndIconshowEndIcon__fIEF5 {
  color: #90908c;
}
.PlasmicTextInput_slotTargetEndIconcolor_dark__3vSX- {
  color: #ffffff;
}
.PlasmicTextInput_svg__kQqJi__2MMDx {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
}

.PlasmicButton_root__2-qYY {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.PlasmicButton_root__2-qYY > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 43.034px);
  height: calc(100% + 43.034px);
}
.PlasmicButton_root__2-qYY > .__wab_flex-container > *,
.PlasmicButton_root__2-qYY > .__wab_flex-container > .__wab_slot > *,
.PlasmicButton_root__2-qYY > .__wab_flex-container > picture > img,
.PlasmicButton_root__2-qYY > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 43.034px;
}
.PlasmicButton_frame9__11jRx {
  flex-direction: row;
  background: var(--token-0kv5OK8yc);
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  top: auto;
  left: auto;
  cursor: pointer;
  min-width: 0;
  padding: 16px 0px;
}
.PlasmicButton_frame9__11jRx > .__wab_flex-container {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - 10px);
  width: calc(100% + 10px);
}
.PlasmicButton_frame9__11jRx > .__wab_flex-container > *,
.PlasmicButton_frame9__11jRx > .__wab_flex-container > .__wab_slot > *,
.PlasmicButton_frame9__11jRx > .__wab_flex-container > picture > img,
.PlasmicButton_frame9__11jRx
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 10px;
}
.PlasmicButton_frame9disabled__2ErEh {
  opacity: 0.5;
}
.PlasmicButton_freeBox__2UIDw {
  display: flex;
  position: relative;
  height: auto;
}
.PlasmicButton_slotTargetChildren__35FTb {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1;
  letter-spacing: 0px;
  text-transform: uppercase;
}

.PlasmicLogin_root__bvKRx {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: #212022;
  min-width: 0;
  min-height: 0;
  padding: 24px;
}
.PlasmicLogin_root__bvKRx > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.PlasmicLogin_root__bvKRx > .__wab_flex-container > *,
.PlasmicLogin_root__bvKRx > .__wab_flex-container > .__wab_slot > *,
.PlasmicLogin_root__bvKRx > .__wab_flex-container > picture > img,
.PlasmicLogin_root__bvKRx > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 16px;
}
.PlasmicLogin_frame5__380tc {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  overflow: hidden;
}
.PlasmicLogin_frame5__380tc > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.PlasmicLogin_frame5__380tc > .__wab_flex-container > *,
.PlasmicLogin_frame5__380tc > .__wab_flex-container > .__wab_slot > *,
.PlasmicLogin_frame5__380tc > .__wab_flex-container > picture > img,
.PlasmicLogin_frame5__380tc
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10px;
}
.PlasmicLogin_img__lx_LA {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.PlasmicLogin_img__lx_LA > picture > img {
  object-fit: cover;
}
.PlasmicLogin_img__lx_LA > .__wab_img-spacer > img {
  object-fit: cover;
}
.PlasmicLogin_wrapper__3ozjI {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.PlasmicLogin_wrapper__3ozjI > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.PlasmicLogin_wrapper__3ozjI > .__wab_flex-container > *,
.PlasmicLogin_wrapper__3ozjI > .__wab_flex-container > .__wab_slot > *,
.PlasmicLogin_wrapper__3ozjI > .__wab_flex-container > picture > img,
.PlasmicLogin_wrapper__3ozjI
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 24px;
}
.PlasmicLogin_preloader__16ytZ {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicLogin_preloaderpreloading__1Zb1Z {
  display: flex;
}
.PlasmicLogin_form__1E0jh {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.PlasmicLogin_form__1E0jh > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.PlasmicLogin_form__1E0jh > .__wab_flex-container > *,
.PlasmicLogin_form__1E0jh > .__wab_flex-container > .__wab_slot > *,
.PlasmicLogin_form__1E0jh > .__wab_flex-container > picture > img,
.PlasmicLogin_form__1E0jh > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 40px;
}
.PlasmicLogin_formpreloading__1ZWB5 {
  display: none;
}
.PlasmicLogin_inputs__LfC3E {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.PlasmicLogin_inputs__LfC3E > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.PlasmicLogin_inputs__LfC3E > .__wab_flex-container > *,
.PlasmicLogin_inputs__LfC3E > .__wab_flex-container > .__wab_slot > *,
.PlasmicLogin_inputs__LfC3E > .__wab_flex-container > picture > img,
.PlasmicLogin_inputs__LfC3E
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 24px;
}
.PlasmicLogin_email__uEWXx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PlasmicLogin_svg___2Bt9I__2G5ox {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicLogin_password__24SEx.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PlasmicLogin_svg__nUwrB__sVzRZ {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicLogin_text__rLbdI {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  min-width: 0;
  display: none;
}
.PlasmicLogin_texterror__1VlTy {
  display: block;
}

.PlasmicHeader_root__1ociT {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 400px;
  position: relative;
  min-width: 0;
}
.PlasmicHeader_root__1ociT > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicHeader_root__1ociT > .__wab_flex-container > *,
.PlasmicHeader_root__1ociT > .__wab_flex-container > .__wab_slot > *,
.PlasmicHeader_root__1ociT > .__wab_flex-container > picture > img,
.PlasmicHeader_root__1ociT > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 12px;
}
.PlasmicHeader_frame6__plX9G {
  flex-direction: row;
  height: auto;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.PlasmicHeader_frame6__plX9G > .__wab_flex-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - 25px);
  width: calc(100% + 25px);
}
.PlasmicHeader_frame6__plX9G > .__wab_flex-container > *,
.PlasmicHeader_frame6__plX9G > .__wab_flex-container > .__wab_slot > *,
.PlasmicHeader_frame6__plX9G > .__wab_flex-container > picture > img,
.PlasmicHeader_frame6__plX9G
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 25px;
}
.PlasmicHeader_img__21LZp {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: auto;
  height: 49px;
}
.PlasmicHeader_img__21LZp > picture > img {
  object-fit: cover;
}
.PlasmicHeader_img__21LZp > .__wab_img-spacer > img {
  object-fit: cover;
}
.PlasmicHeader_logo__K9U8P {
  background: url("https://cdn-icons-png.flaticon.com/512/21/21104.png") top 50%
      left 50% / cover repeat,
    var(--token-0kv5OK8yc);
  width: 49px;
  height: 49px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.PlasmicHeader_frame8__GgqHq {
  flex-direction: row;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicHeader_frame8__GgqHq > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.PlasmicHeader_frame8__GgqHq > .__wab_flex-container > *,
.PlasmicHeader_frame8__GgqHq > .__wab_flex-container > .__wab_slot > *,
.PlasmicHeader_frame8__GgqHq > .__wab_flex-container > picture > img,
.PlasmicHeader_frame8__GgqHq
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 12px;
}
.PlasmicHeader_text__34o0f {
  display: block;
  font-size: 16.137px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicHeader_name__1FUoX {
  display: block;
  font-size: 16.137px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}

.PlasmicCheckbox_root__1bVQe {
  flex-direction: row;
  display: inline-flex;
  position: relative;
  width: auto;
  height: auto;
}
.PlasmicCheckbox_root__1bVQe > .__wab_flex-container {
  flex-direction: row;
  align-items: center;
  margin-left: calc(0px - 4px);
  width: calc(100% + 4px);
}
.PlasmicCheckbox_root__1bVQe > .__wab_flex-container > *,
.PlasmicCheckbox_root__1bVQe > .__wab_flex-container > .__wab_slot > *,
.PlasmicCheckbox_root__1bVQe > .__wab_flex-container > picture > img,
.PlasmicCheckbox_root__1bVQe > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 4px;
}
.PlasmicCheckbox_root__1bVQe:focus-within {
  outline: none;
}
.PlasmicCheckbox_root___focusVisibleWithin__Do6Ms {
  outline: none;
}
.PlasmicCheckbox_freeBox__142qJ {
  display: flex;
  position: relative;
  transition-property: all;
  transition-duration: 0.2s;
  align-items: center;
  justify-content: center;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  border-radius: 4px;
}
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_freeBox__142qJ {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.PlasmicCheckbox_root__1bVQe .PlasmicCheckbox_freeBox___focusVisibleWithin__1N7FZ {
  box-shadow: 0px 0px 0px 3px #96c7f2;
  outline: none;
}
.PlasmicCheckbox_svg__1h_ye {
  position: relative;
  object-fit: cover;
  color: #dbdbd7;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  display: block;
}
.PlasmicCheckbox_svgisChecked__2mZe4 {
  color: #0091ff;
}
.PlasmicCheckbox_svgisIndeterminate__2uoEk {
  color: #0091ff;
}
.PlasmicCheckbox_root__1bVQe:hover .PlasmicCheckbox_svg__1h_ye {
  color: #c8c7c1;
}
.PlasmicCheckbox_rootisDisabled__3CPf7:hover .PlasmicCheckbox_svgisDisabled__1sp-d {
  color: #dbdbd7;
}
.PlasmicCheckbox_rootisChecked__3--Fx:hover .PlasmicCheckbox_svgisChecked__2mZe4 {
  color: #0081f1;
}
.PlasmicCheckbox_rootisIndeterminate__fnujy:hover .PlasmicCheckbox_svgisIndeterminate__2uoEk {
  color: #0081f1;
}
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_svg__1h_ye {
  outline: none;
}
.PlasmicCheckbox_root__1bVQe .PlasmicCheckbox_svg___focusVisibleWithin__e-o9b {
  outline: none;
}
.PlasmicCheckbox_labelContainer__32Vn1 {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_labelContainer__32Vn1 {
  outline: none;
}
.PlasmicCheckbox_root__1bVQe .PlasmicCheckbox_labelContainer___focusVisibleWithin__2A4nQ {
  outline: none;
}
.PlasmicCheckbox_slotTargetChildren__3O3Ms {
  white-space: pre;
}
.PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_text,
.PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot-string-wrapper,
.PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot > .__wab_text,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_text,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot-string-wrapper {
  text-overflow: ellipsis;
}
.PlasmicCheckbox_slotTargetChildren__3O3Ms > *,
.PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot > *,
.PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot > .__wab_slot > *,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.PlasmicCheckbox_slotTargetChildren__3O3Ms > picture > img,
.PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot > picture > img,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  overflow: hidden;
}
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_slotTargetChildren__3O3Ms > *,
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot > *,
.PlasmicCheckbox_root__1bVQe:focus-within
  .PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > *,
.PlasmicCheckbox_root__1bVQe:focus-within
  .PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_slotTargetChildren__3O3Ms > picture > img,
.PlasmicCheckbox_root__1bVQe:focus-within .PlasmicCheckbox_slotTargetChildren__3O3Ms > .__wab_slot > picture > img,
.PlasmicCheckbox_root__1bVQe:focus-within
  .PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.PlasmicCheckbox_root__1bVQe:focus-within
  .PlasmicCheckbox_slotTargetChildren__3O3Ms
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  outline: none;
}
.PlasmicCheckbox_root__1bVQe .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq > *,
.PlasmicCheckbox_root__1bVQe .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq > .__wab_slot > *,
.PlasmicCheckbox_root__1bVQe
  .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq
  > .__wab_slot
  > .__wab_slot
  > *,
.PlasmicCheckbox_root__1bVQe
  .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > *,
.PlasmicCheckbox_root__1bVQe .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq > picture > img,
.PlasmicCheckbox_root__1bVQe
  .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq
  > .__wab_slot
  > picture
  > img,
.PlasmicCheckbox_root__1bVQe
  .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq
  > .__wab_slot
  > .__wab_slot
  > picture
  > img,
.PlasmicCheckbox_root__1bVQe
  .PlasmicCheckbox_slotTargetChildren___focusVisibleWithin__2FBXq
  > .__wab_slot
  > .__wab_slot
  > .__wab_slot
  > picture
  > img {
  outline: none;
}

.PlasmicOrdersFilters_root__1CE3j {
  flex-direction: row;
  display: inline-flex;
  position: relative;
  width: auto;
  height: auto;
}
.PlasmicOrdersFilters_root__1CE3j > .__wab_flex-container {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.PlasmicOrdersFilters_root__1CE3j > .__wab_flex-container > *,
.PlasmicOrdersFilters_root__1CE3j > .__wab_flex-container > .__wab_slot > *,
.PlasmicOrdersFilters_root__1CE3j > .__wab_flex-container > picture > img,
.PlasmicOrdersFilters_root__1CE3j > .__wab_flex-container > .__wab_slot > picture > img {
  margin-left: 20px;
}
.PlasmicOrdersFilters_search__27P87.__wab_instance {
  max-width: 100%;
  position: relative;
  height: 35px;
}
.PlasmicOrdersFilters_svg__mexap__2_8wJ {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicOrdersFilters_svg__aMpD__17OLR {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicOrdersFilters_hidePasted__2BDtE.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PlasmicOrdersFilters_text__3rOtp {
  color: #ffffff;
}

.PlasmicBarcodeScanner_root__2veen {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.PlasmicBarcodeScanner_root__2veen > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicBarcodeScanner_root__2veen > .__wab_flex-container > *,
.PlasmicBarcodeScanner_root__2veen > .__wab_flex-container > .__wab_slot > *,
.PlasmicBarcodeScanner_root__2veen > .__wab_flex-container > picture > img,
.PlasmicBarcodeScanner_root__2veen > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 12px;
}
.PlasmicBarcodeScanner_scanner__373Ez {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.PlasmicBarcodeScanner_scannerscanned__MJkSV {
  display: none;
}
.PlasmicBarcodeScanner_freeBox__eELw9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicBarcodeScanner_freeBoxscanned__2C6CM {
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 0;
  display: flex;
}
.PlasmicBarcodeScanner_title__2sEwQ {
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  display: none;
}
.PlasmicBarcodeScanner_titlescanned__1F_YV {
  display: block;
}
.PlasmicBarcodeScanner_code__2WaA0 {
  position: relative;
  plasmic-display-none: true;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
}
.PlasmicBarcodeScanner_codescanned__2D7Nd {
  display: block;
}
.PlasmicBarcodeScanner_cancel__2uGL7.__wab_instance {
  max-width: 100%;
  position: relative;
}

.PlasmicActions_root__XkdeS {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--token-BRpR8JzSS);
  min-width: 0;
  min-height: 0;
  padding: 24px;
}
.PlasmicActions_root__XkdeS > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.PlasmicActions_root__XkdeS > .__wab_flex-container > *,
.PlasmicActions_root__XkdeS > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_root__XkdeS > .__wab_flex-container > picture > img,
.PlasmicActions_root__XkdeS > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 16px;
}
.PlasmicActions_frame5__32SG6 {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  overflow: visible;
  z-index: 2;
  min-width: 0;
}
.PlasmicActions_frame5__32SG6 > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.PlasmicActions_frame5__32SG6 > .__wab_flex-container > *,
.PlasmicActions_frame5__32SG6 > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_frame5__32SG6 > .__wab_flex-container > picture > img,
.PlasmicActions_frame5__32SG6
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10px;
}
.PlasmicActions_wrapper___Bw0x {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.PlasmicActions_wrapper___Bw0x > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.PlasmicActions_wrapper___Bw0x > .__wab_flex-container > *,
.PlasmicActions_wrapper___Bw0x > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_wrapper___Bw0x > .__wab_flex-container > picture > img,
.PlasmicActions_wrapper___Bw0x
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 24px;
}
.PlasmicActions_title__sjGxR__2wkSj.__wab_instance {
  width: 100%;
  min-width: 0;
}
.PlasmicActions_textorder__sr6SGuaTvu__XvOvS {
  display: none;
}
.PlasmicActions_textorderCompleted__sr6SGmFCkN__2dr7o {
  display: none;
}
.PlasmicActions_textorderSearching__sr6SgDDnx__3sBQ6 {
  display: none;
}
.PlasmicActions_textorderSelect__sr6SGmxEBu__OMDT4 {
  display: none;
}
.PlasmicActions_ordersTitle__83Xvp {
  height: auto;
}
.PlasmicActions_ordersTitleorder__2wU6b {
  display: none;
}
.PlasmicActions_ordersTitleorderCompleted__2jEpq {
  display: none;
}
.PlasmicActions_ordersTitleorderSearching__3Z-UM {
  display: none;
}
.PlasmicActions_orderTitle__TUYbf {
  display: none;
}
.PlasmicActions_orderTitleorder__20oY6 {
  display: none;
}
.PlasmicActions_orderTitleorderCompleted__BqEh5 {
  display: none;
}
.PlasmicActions_orderTitleWrapper__1GNP7 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.PlasmicActions_orderTitleWrapperorderCompleted__60cO9 {
  display: flex;
}
.PlasmicActions_orderTitleWrapperorderSearching__2WLKP {
  display: flex;
}
.PlasmicActions_frame17__i7kS4 {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_frame17__i7kS4 > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 4px);
  height: calc(100% + 4px);
}
.PlasmicActions_frame17__i7kS4 > .__wab_flex-container > *,
.PlasmicActions_frame17__i7kS4 > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_frame17__i7kS4 > .__wab_flex-container > picture > img,
.PlasmicActions_frame17__i7kS4
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 4px;
}
.PlasmicActions_text__yhziF__2o8Dp {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_textorderCompleted__yhziFmFCkN__382Bq {
  padding-top: 0px;
}
.PlasmicActions_skiName__1hX5B {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_skiNameorderSearching__1QkPd {
  display: none;
}
.PlasmicActions_number__1tNGv {
  display: block;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_numberorderSearching__2uGQw {
  padding-left: 0px;
}
.PlasmicActions_putToText__LrDwI {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  padding: 24px 0px;
}
.PlasmicActions_putToText__LrDwI > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicActions_putToText__LrDwI > .__wab_flex-container > *,
.PlasmicActions_putToText__LrDwI > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_putToText__LrDwI > .__wab_flex-container > picture > img,
.PlasmicActions_putToText__LrDwI
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicActions_frame13__1by5A {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_frame13__1by5A > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_frame13__1by5A > .__wab_flex-container > *,
.PlasmicActions_frame13__1by5A > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_frame13__1by5A > .__wab_flex-container > picture > img,
.PlasmicActions_frame13__1by5A
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_text__xb1W9__79WGP {
  display: block;
  font-size: 16.137px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_frame14__2JZVw {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_frame14__2JZVw > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_frame14__2JZVw > .__wab_flex-container > *,
.PlasmicActions_frame14__2JZVw > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_frame14__2JZVw > .__wab_flex-container > picture > img,
.PlasmicActions_frame14__2JZVw
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_containerNumber__3VB0J {
  display: block;
  font-size: 40px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_orderInfo__2ioeX {
  flex-direction: column;
  width: auto;
  height: auto;
  display: none;
}
.PlasmicActions_orderInfo__2ioeX > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicActions_orderInfo__2ioeX > .__wab_flex-container > *,
.PlasmicActions_orderInfo__2ioeX > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_orderInfo__2ioeX > .__wab_flex-container > picture > img,
.PlasmicActions_orderInfo__2ioeX
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicActions_orderInfodone__1Wry0 {
  display: flex;
}
.PlasmicActions_theOrder__3UXeS {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_orderTxt__E5JX8 {
  display: block;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_text__g2Bps__1mmgQ {
  display: block;
  font-size: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_preloader__3LOjm {
  plasmic-display-none: false;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicActions_preloaderpreloading__1E4Md {
  display: flex;
}
.PlasmicActions_preloaderorderSearching__3pF2E {
  display: flex;
}
.PlasmicActions_freeBox___4Ev9__3X5bH {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.PlasmicActions_freeBoxorderSelect___4Ev9MxEBu__3n8FV {
  display: flex;
}
.PlasmicActions_filters__1GTo5.__wab_instance {
  max-width: 100%;
  display: none;
}
.PlasmicActions_filtersorderSelect__i8Pvt.__wab_instance {
  display: flex;
}
.PlasmicActions_orderInfoWrapper__34wz1 {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
}
.PlasmicActions_orderInfoWrapper__34wz1 > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.PlasmicActions_orderInfoWrapper__34wz1 > .__wab_flex-container > *,
.PlasmicActions_orderInfoWrapper__34wz1 > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_orderInfoWrapper__34wz1 > .__wab_flex-container > picture > img,
.PlasmicActions_orderInfoWrapper__34wz1
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 24px;
}
.PlasmicActions_freeBox__zLJyY__2UFn8 {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_freeBox__zLJyY__2UFn8 > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicActions_freeBox__zLJyY__2UFn8 > .__wab_flex-container > *,
.PlasmicActions_freeBox__zLJyY__2UFn8 > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_freeBox__zLJyY__2UFn8 > .__wab_flex-container > picture > img,
.PlasmicActions_freeBox__zLJyY__2UFn8
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicActions_text__xjUpI__3cVEp {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_freeBox__nuzJ__1qwmZ {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_freeBox__nuzJ__1qwmZ > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_freeBox__nuzJ__1qwmZ > .__wab_flex-container > *,
.PlasmicActions_freeBox__nuzJ__1qwmZ > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_freeBox__nuzJ__1qwmZ > .__wab_flex-container > picture > img,
.PlasmicActions_freeBox__nuzJ__1qwmZ
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_orderDate__2Wbes {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_servicesWrapper__1i-bM {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_servicesWrapper__1i-bM > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicActions_servicesWrapper__1i-bM > .__wab_flex-container > *,
.PlasmicActions_servicesWrapper__1i-bM > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_servicesWrapper__1i-bM > .__wab_flex-container > picture > img,
.PlasmicActions_servicesWrapper__1i-bM
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicActions_serviceTitle__1-3bR {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_serviceTitle__1-3bR > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_serviceTitle__1-3bR > .__wab_flex-container > *,
.PlasmicActions_serviceTitle__1-3bR > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_serviceTitle__1-3bR > .__wab_flex-container > picture > img,
.PlasmicActions_serviceTitle__1-3bR
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_text___55Diq__1DAbQ {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_serviceItems__3Umez {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_serviceItems__3Umez > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_serviceItems__3Umez > .__wab_flex-container > *,
.PlasmicActions_serviceItems__3Umez > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_serviceItems__3Umez > .__wab_flex-container > picture > img,
.PlasmicActions_serviceItems__3Umez
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_text__gdi4G__uW0sM {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_text__pegar__2FPUH {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_commentWrapper__rKSrW {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_commentWrapper__rKSrW > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicActions_commentWrapper__rKSrW > .__wab_flex-container > *,
.PlasmicActions_commentWrapper__rKSrW > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_commentWrapper__rKSrW > .__wab_flex-container > picture > img,
.PlasmicActions_commentWrapper__rKSrW
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 12px;
}
.PlasmicActions_commentTitle__ArPgY {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_commentTitle__ArPgY > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_commentTitle__ArPgY > .__wab_flex-container > *,
.PlasmicActions_commentTitle__ArPgY > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_commentTitle__ArPgY > .__wab_flex-container > picture > img,
.PlasmicActions_commentTitle__ArPgY
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_text__hs19W__3A-bf {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.PlasmicActions_serviceItems2__1PjiC {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  position: relative;
}
.PlasmicActions_serviceItems2__1PjiC > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.PlasmicActions_serviceItems2__1PjiC > .__wab_flex-container > *,
.PlasmicActions_serviceItems2__1PjiC > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_serviceItems2__1PjiC > .__wab_flex-container > picture > img,
.PlasmicActions_serviceItems2__1PjiC
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-top: 10.758px;
}
.PlasmicActions_comment__Tfbhb {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
  padding-right: 0px;
}
.PlasmicActions_form__1kq0N {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.PlasmicActions_form__1kq0N > .__wab_flex-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.PlasmicActions_form__1kq0N > .__wab_flex-container > *,
.PlasmicActions_form__1kq0N > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_form__1kq0N > .__wab_flex-container > picture > img,
.PlasmicActions_form__1kq0N > .__wab_flex-container > .__wab_slot > picture > img {
  margin-top: 12px;
}
.PlasmicActions_formloading__3HpOY {
  display: flex;
}
.PlasmicActions_formpreloading__3jxs3 {
  display: none;
}
.PlasmicActions_formorderSelect__r1m5L {
  height: 100%;
  min-height: 0;
}
.PlasmicActions_formorderSelect__r1m5L > .__wab_flex-container {
  min-height: 0;
}
.PlasmicActions_complete__330hZ.__wab_instance {
  display: none;
}
.PlasmicActions_completeorderCompleted__DB7z7.__wab_instance {
  display: flex;
}
.PlasmicActions_text__x5WV__30hHX {
  padding-right: 0px;
}
.PlasmicActions_scan___TJS0.__wab_instance {
  display: none;
}
.PlasmicActions_scanorder__BwVaN.__wab_instance {
  display: none;
}
.PlasmicActions_scanorderCompleted__1SG5n.__wab_instance {
  display: none;
}
.PlasmicActions_scanenterCode__1Lx3X.__wab_instance {
  display: none;
}
.PlasmicActions_completeOrder__3EzPf.__wab_instance {
  plasmic-display-none: false;
}
.PlasmicActions_completeOrderorder__2XBsM.__wab_instance {
  display: flex;
}
.PlasmicActions_completeOrderorderCompleted__1K-Rp.__wab_instance {
  display: none;
}
.PlasmicActions_completeOrderenterCode__2vTI4.__wab_instance {
  display: none;
}
.PlasmicActions_selectPlace__3YAdm.__wab_instance {
  plasmic-display-none: false;
}
.PlasmicActions_selectPlaceorder__10RP-.__wab_instance {
  display: none;
}
.PlasmicActions_selectPlaceorderCompleted__1siYD.__wab_instance {
  display: none;
}
.PlasmicActions_textorderCompleted___9PfFmmFCkN__39AbW {
  padding-right: 0px;
  height: auto;
}
.PlasmicActions_cancelOrderorderCompleted__3LRyz.__wab_instance {
  display: none;
}
.PlasmicActions_textorderCompleted__hj6OKmFCkN__1e2bO {
  padding-right: 0px;
}
.PlasmicActions_backToOrderorder__37NQi.__wab_instance {
  display: none;
}
.PlasmicActions_backToOrderorderCompleted__2AYFk.__wab_instance {
  display: flex;
}
.PlasmicActions_textorderCompleted__kjd2RmFCkN__3Ewcu {
  padding-right: 0px;
}
.PlasmicActions_enterCodeBtnorder__2n2Zi.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnscanning__UuaiO.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnloading__3eiKQ.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnorderCompleted__3mfn3.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnenterCode__2Q4JR.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnorderSearching__2DcrM.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnorderError__3dn3E.__wab_instance {
  display: none;
}
.PlasmicActions_enterCodeBtnorderSelect__11EVK.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeorder__1B9WX.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodescanning__3KdH1.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeloading__2ybMd.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeorderCompleted__3YuPw.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeenterCode__rzthf.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeorderSearching__IxmPz.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeorderError__3FQTm.__wab_instance {
  display: none;
}
.PlasmicActions_scanBarcodeorderSelect__2nQev.__wab_instance {
  display: none;
}
.PlasmicActions_text___9GQ4__2e6nS {
  height: auto;
}
@media (min-width: 768px) {
  .PlasmicActions_text___9GQ4__2e6nS {
    height: auto;
  }
}
.PlasmicActions_showOrderList__2iyCL.__wab_instance {
  display: flex;
}
.PlasmicActions_showOrderListorder__39VLA.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListscanning__eK6qU.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListloading__2erSB.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListorderCompleted__1SEZe.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListenterCode__oy8c5.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListorderSearching__2QFeh.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListorderError__2b5xv.__wab_instance {
  display: none;
}
.PlasmicActions_showOrderListorderSelect__2R-mv.__wab_instance {
  display: none;
}
@media (min-width: 768px) {
  .PlasmicActions_text__ao25Y__3PrMm {
    height: auto;
  }
}
.PlasmicActions_orders__3mLxO {
  flex-direction: column;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicActions_orders__3mLxO > .__wab_flex-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
}
.PlasmicActions_ordersorderSelect__1pvjY {
  height: 100%;
  min-height: 0;
  display: flex;
}
.PlasmicActions_ordersorderSelect__1pvjY > .__wab_flex-container {
  justify-content: flex-start;
  align-items: center;
  min-height: 0;
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - 20px);
  height: calc(100% + 20px);
}
.PlasmicActions_ordersorderSelect__1pvjY > .__wab_flex-container > *,
.PlasmicActions_ordersorderSelect__1pvjY > .__wab_flex-container > .__wab_slot > *,
.PlasmicActions_ordersorderSelect__1pvjY > .__wab_flex-container > picture > img,
.PlasmicActions_ordersorderSelect__1pvjY
  > .__wab_flex-container
  > .__wab_slot
  > picture
  > img {
  margin-left: 0px;
  margin-top: 20px;
}
.PlasmicActions_code__3PWu7.__wab_instance {
  max-width: 100%;
  position: relative;
  margin-bottom: 24px;
  display: none;
}
.PlasmicActions_codeenterCode__2WJup.__wab_instance {
  display: flex;
}
.PlasmicActions_codeorderError__cmXmq.__wab_instance {
  display: flex;
}
.PlasmicActions_svg__ssMxN__14R8P {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicActions_svg__mqpk__2ZTVd {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.PlasmicActions_codeErrorWrapper__2c6_c {
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 12px 8px;
}
.PlasmicActions_codeErrorWrapperenterCode__6MPZT {
  display: none;
}
.PlasmicActions_freeBox__hbsBs__F5tZE {
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: auto;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  display: none;
  border-radius: 4px;
  padding: 4px 24px;
}
.PlasmicActions_freeBoxenterCode__hbsBszyhI4__2RJx7 {
  display: flex;
}
.PlasmicActions_codeErrrorTxt__2c_GW {
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-0kv5OK8yc);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  min-width: 0;
  display: none;
}
.PlasmicActions_codeErrrorTxtenterCode__1NLpd {
  display: none;
}
.PlasmicActions_codeStatusTxt__xTwaq {
  width: 100%;
  height: auto;
  max-width: 800px;
  text-align: center;
  color: var(--token-5JSkJ0nFu);
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  min-width: 0;
}
.PlasmicActions_errorTxt__71qui {
  plasmic-display-none: false;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  min-width: 0;
}
.PlasmicActions_submitCodeorder__2ZVHj.__wab_instance {
  display: none;
}
.PlasmicActions_submitCodeorderCompleted__3VkRi.__wab_instance {
  display: none;
}
.PlasmicActions_submitCodeorderSearching__1m7qh.__wab_instance {
  display: flex;
}
.PlasmicActions_submitCodeorderError__2-rqZ.__wab_instance {
  display: flex;
}
.PlasmicActions_cancelCodeorder__d4qzj.__wab_instance {
  display: none;
}
.PlasmicActions_cancelCodeorderCompleted__qdYFY.__wab_instance {
  display: none;
}
.PlasmicActions_cancelCodeorderError__A-s6_.__wab_instance {
  display: flex;
}
.PlasmicActions_text__snrhd__14m1Y {
  padding-right: 0px;
}
.PlasmicActions_preloaderContainer__1tbkG {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicActions_preloaderContainerloading__14Wg7 {
  display: flex;
}
.PlasmicActions_scanner__2Njkz.__wab_instance {
  max-width: 100%;
  position: relative;
  height: 60vh;
  flex-shrink: 0;
  display: none;
}
.PlasmicActions_scannerscanning__UoS_8.__wab_instance {
  display: flex;
}
.PlasmicActions_freeBox___0Xhqx__7D9H1 {
  position: absolute;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  z-index: 2;
  top: auto;
  bottom: 0px;
  height: 100px;
  min-width: 0;
  flex-shrink: 0;
  display: none;
  padding: 8px;
}
.PlasmicActions_freeBoxmenuOpen___0XhqXrstHg__GR5gi {
  left: auto;
}
.PlasmicActions_freeBoxuserMenu___0Xhqxiyx4X__3DEfx {
  display: flex;
}
.PlasmicActions_actions__3o7Rg {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 400px;
  position: relative;
  top: auto;
  z-index: 1;
  bottom: auto;
  min-width: 0;
  display: none;
  padding: 8px;
}
.PlasmicActions_actionsmenuOpen__oKwrN {
  max-width: 400px;
}
.PlasmicActions_actionsuserMenu__1n1ed {
  display: flex;
}
.PlasmicActions_logout__32X8f.__wab_instance {
  max-width: 100%;
  position: relative;
}
.PlasmicActions_fade__m3O_g {
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  left: 0px;
  top: -16px;
  background: #21202240;
  backdrop-filter: blur(7px);
  z-index: 1;
  flex-shrink: 0;
  display: none;
  -webkit-backdrop-filter: blur(7px);
  padding: 0px;
}
.PlasmicActions_fadeuserMenu__cMfAU {
  display: block;
}

