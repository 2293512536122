.root {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  max-width: 400px;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.frame6 {
  flex-direction: row;
  height: auto;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.frame6 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - 25px);
  width: calc(100% + 25px);
}
.frame6 > :global(.__wab_flex-container) > *,
.frame6 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame6 > :global(.__wab_flex-container) > picture > img,
.frame6
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 25px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
  align-self: auto;
  height: 49px;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.logo {
  background: url("https://cdn-icons-png.flaticon.com/512/21/21104.png") top 50%
      left 50% / cover repeat,
    var(--token-0kv5OK8yc);
  width: 49px;
  height: 49px;
  display: block;
  position: relative;
  flex-shrink: 0;
}
.frame8 {
  flex-direction: row;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.frame8 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.frame8 > :global(.__wab_flex-container) > *,
.frame8 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame8 > :global(.__wab_flex-container) > picture > img,
.frame8
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.text {
  display: block;
  font-size: 16.137px;
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.name {
  display: block;
  font-size: 16.137px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
