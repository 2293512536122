.root {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 10.758px;
}
.signIn {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 0;
}
.slotTargetChildren {
  font-family: "Open Sans", sans-serif;
  font-size: 16.137px;
  font-weight: 700;
  color: rgba(255, 255, 255, 1);
  line-height: 1;
  letter-spacing: 0px;
  text-align: center;
}
.line:global(.__wab_instance) {
  position: relative;
  align-self: stretch;
  flex-shrink: 0;
}
