.root {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 12px;
}
.scanner {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  padding: 8px;
}
.scannerscanned {
  display: none;
}
.freeBox {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 8px;
}
.freeBoxscanned {
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: 0;
  display: flex;
}
.title {
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  display: none;
}
.titlescanned {
  display: block;
}
.code {
  position: relative;
  plasmic-display-none: true;
  width: auto;
  height: auto;
  max-width: 800px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
}
.codescanned {
  display: block;
}
.cancel:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
