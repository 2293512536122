.root {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  background: #212022;
  min-width: 0;
  min-height: 0;
  padding: 24px;
}
.root > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 16px);
  height: calc(100% + 16px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 16px;
}
.frame5 {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  overflow: hidden;
}
.frame5 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame5 > :global(.__wab_flex-container) > *,
.frame5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame5 > :global(.__wab_flex-container) > picture > img,
.frame5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.wrapper {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 400px;
  min-width: 0;
  min-height: 0;
  padding: 0px;
}
.wrapper > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  min-height: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.wrapper > :global(.__wab_flex-container) > *,
.wrapper > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.wrapper > :global(.__wab_flex-container) > picture > img,
.wrapper
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.preloader {
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  display: none;
  padding: 8px;
}
.preloaderpreloading {
  display: flex;
}
.form {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.form > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 0;
  margin-top: calc(0px - 40px);
  height: calc(100% + 40px);
}
.form > :global(.__wab_flex-container) > *,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.form > :global(.__wab_flex-container) > picture > img,
.form > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 40px;
}
.formpreloading {
  display: none;
}
.inputs {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.inputs > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.inputs > :global(.__wab_flex-container) > *,
.inputs > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.inputs > :global(.__wab_flex-container) > picture > img,
.inputs
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.email:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg___2Bt9I {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.password:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.svg__nUwrB {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.text {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  color: #ff0000;
  min-width: 0;
  display: none;
}
.texterror {
  display: block;
}
