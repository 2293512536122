.root {
  flex-direction: row;
  height: auto;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 0;
  margin-left: calc(0px - 11px);
  width: calc(100% + 11px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 11px;
}
.name {
  color: #ffffff;
}
.price {
  color: #ffffff;
}
