@import url("https://fonts.googleapis.com/css2?family=Inter%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Inconsolata%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C500%3B0%2C600%3B0%2C700%3B0%2C900&family=Open+Sans%3Aital%2Cwght%400%2C300%3B0%2C400%3B0%2C600%3B0%2C700%3B0%2C800&display=swap");

.plasmic_tokens {
  --token-0kv5OK8yc: #b9042d;
  --plasmic-token-primary: var(--token-0kv5OK8yc);
  --token-5JSkJ0nFu: #616161;
  --plasmic-token-unnamed-style-token: var(--token-5JSkJ0nFu);
  --token-BRpR8JzSS: #231f20;
  --plasmic-token-background: var(--token-BRpR8JzSS);
}

.plasmic_default_styles {
  --mixin-X00bFdoWre_font-family: "Inter", sans-serif;
  --mixin-X00bFdoWre_font-size: 16px;
  --mixin-X00bFdoWre_font-weight: 400;
  --mixin-X00bFdoWre_font-style: normal;
  --mixin-X00bFdoWre_color: #535353;
  --mixin-X00bFdoWre_text-align: left;
  --mixin-X00bFdoWre_text-transform: none;
  --mixin-X00bFdoWre_line-height: 1.5;
  --mixin-X00bFdoWre_letter-spacing: normal;
  --mixin-X00bFdoWre_white-space: pre-wrap;
  --mixin-X00bFdoWre_user-select: text;
  --mixin-X00bFdoWre_text-decoration-line: none;
  --mixin-X00bFdoWre_text-overflow: clip;
  --mixin-vWv7grl6YD_font-family: "Inter", sans-serif;
  --mixin-vWv7grl6YD_color: #000000;
  --mixin-vWv7grl6YD_font-size: 24px;
  --mixin-vWv7grl6YD_font-weight: 600;
  --mixin-vWv7grl6YD_letter-spacing: -0.5px;
  --mixin-vWv7grl6YD_line-height: 1.3;
  --mixin-vWv7grl6YD_white-space: pre-wrap;
  --mixin-iNbojl0nhI_font-family: "Inter", sans-serif;
  --mixin-iNbojl0nhI_color: #000000;
  --mixin-iNbojl0nhI_font-size: 20px;
  --mixin-iNbojl0nhI_font-weight: 600;
  --mixin-iNbojl0nhI_letter-spacing: -0.3px;
  --mixin-iNbojl0nhI_line-height: 1.5;
  --mixin-iNbojl0nhI_white-space: pre-wrap;
  --mixin-wy-2o8AKq-_font-family: "Inter", sans-serif;
  --mixin-wy-2o8AKq-_color: #000000;
  --mixin-wy-2o8AKq-_font-size: 16px;
  --mixin-wy-2o8AKq-_font-weight: 600;
  --mixin-wy-2o8AKq-_line-height: 1.5;
  --mixin-wy-2o8AKq-_white-space: pre-wrap;
  --mixin-tVe6SYYSop_color: #0070f3;
  --mixin-tVe6SYYSop_white-space: pre-wrap;
  --mixin-ZnKXQ8mijo_border-left-color: #dddddd;
  --mixin-ZnKXQ8mijo_border-left-style: solid;
  --mixin-ZnKXQ8mijo_border-left-width: 3px;
  --mixin-ZnKXQ8mijo_color: #888888;
  --mixin-ZnKXQ8mijo_padding-left: 10px;
  --mixin-ZnKXQ8mijo_white-space: pre-wrap;
  --mixin-tiKRlyKiB_font-family: "Inter", sans-serif;
  --mixin-tiKRlyKiB_color: #000000;
  --mixin-tiKRlyKiB_font-size: 72px;
  --mixin-tiKRlyKiB_font-weight: 900;
  --mixin-tiKRlyKiB_letter-spacing: -4px;
  --mixin-tiKRlyKiB_line-height: 1;
  --mixin-tiKRlyKiB_white-space: pre-wrap;
  --mixin-70DPORDir-_font-family: "Inter", sans-serif;
  --mixin-70DPORDir-_color: #000000;
  --mixin-70DPORDir-_font-size: 48px;
  --mixin-70DPORDir-_font-weight: 700;
  --mixin-70DPORDir-_letter-spacing: -1px;
  --mixin-70DPORDir-_line-height: 1.1;
  --mixin-70DPORDir-_white-space: pre-wrap;
  --mixin-VLfLpuOl-T_font-family: "Inter", sans-serif;
  --mixin-VLfLpuOl-T_color: #000000;
  --mixin-VLfLpuOl-T_font-size: 32px;
  --mixin-VLfLpuOl-T_font-weight: 600;
  --mixin-VLfLpuOl-T_letter-spacing: -0.8px;
  --mixin-VLfLpuOl-T_line-height: 1.2;
  --mixin-VLfLpuOl-T_white-space: pre-wrap;
  --mixin-aSDICzgaZE_color: #3291ff;
  --mixin-aSDICzgaZE_white-space: pre-wrap;
  --mixin-yipuD8O4E8_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-yipuD8O4E8_border-bottom-color: #dddddd;
  --mixin-yipuD8O4E8_border-bottom-style: solid;
  --mixin-yipuD8O4E8_border-bottom-width: 1px;
  --mixin-yipuD8O4E8_border-left-color: #dddddd;
  --mixin-yipuD8O4E8_border-left-style: solid;
  --mixin-yipuD8O4E8_border-left-width: 1px;
  --mixin-yipuD8O4E8_border-right-color: #dddddd;
  --mixin-yipuD8O4E8_border-right-style: solid;
  --mixin-yipuD8O4E8_border-right-width: 1px;
  --mixin-yipuD8O4E8_border-top-color: #dddddd;
  --mixin-yipuD8O4E8_border-top-style: solid;
  --mixin-yipuD8O4E8_border-top-width: 1px;
  --mixin-yipuD8O4E8_border-bottom-left-radius: 3px;
  --mixin-yipuD8O4E8_border-bottom-right-radius: 3px;
  --mixin-yipuD8O4E8_border-top-left-radius: 3px;
  --mixin-yipuD8O4E8_border-top-right-radius: 3px;
  --mixin-yipuD8O4E8_font-family: "Inconsolata";
  --mixin-yipuD8O4E8_padding-bottom: 1px;
  --mixin-yipuD8O4E8_padding-left: 4px;
  --mixin-yipuD8O4E8_padding-right: 4px;
  --mixin-yipuD8O4E8_padding-top: 1px;
  --mixin-yipuD8O4E8_white-space: pre-wrap;
  --mixin-RoQWLylCHO_background: linear-gradient(#f8f8f8, #f8f8f8);
  --mixin-RoQWLylCHO_border-bottom-color: #dddddd;
  --mixin-RoQWLylCHO_border-bottom-style: solid;
  --mixin-RoQWLylCHO_border-bottom-width: 1px;
  --mixin-RoQWLylCHO_border-left-color: #dddddd;
  --mixin-RoQWLylCHO_border-left-style: solid;
  --mixin-RoQWLylCHO_border-left-width: 1px;
  --mixin-RoQWLylCHO_border-right-color: #dddddd;
  --mixin-RoQWLylCHO_border-right-style: solid;
  --mixin-RoQWLylCHO_border-right-width: 1px;
  --mixin-RoQWLylCHO_border-top-color: #dddddd;
  --mixin-RoQWLylCHO_border-top-style: solid;
  --mixin-RoQWLylCHO_border-top-width: 1px;
  --mixin-RoQWLylCHO_border-bottom-left-radius: 3px;
  --mixin-RoQWLylCHO_border-bottom-right-radius: 3px;
  --mixin-RoQWLylCHO_border-top-left-radius: 3px;
  --mixin-RoQWLylCHO_border-top-right-radius: 3px;
  --mixin-RoQWLylCHO_font-family: "Inconsolata";
  --mixin-RoQWLylCHO_padding-bottom: 3px;
  --mixin-RoQWLylCHO_padding-left: 6px;
  --mixin-RoQWLylCHO_padding-right: 6px;
  --mixin-RoQWLylCHO_padding-top: 3px;
  --mixin-RoQWLylCHO_white-space: pre-wrap;
  --mixin-uWGc3Qwcpc_display: flex;
  --mixin-uWGc3Qwcpc_flex-direction: column;
  --mixin-uWGc3Qwcpc_align-items: stretch;
  --mixin-uWGc3Qwcpc_justify-content: flex-start;
  --mixin-uWGc3Qwcpc_list-style-position: outside;
  --mixin-uWGc3Qwcpc_padding-left: 40px;
  --mixin-uWGc3Qwcpc_position: relative;
  --mixin-uWGc3Qwcpc_list-style-type: decimal;
  --mixin-uWGc3Qwcpc_white-space: pre-wrap;
  --mixin-ac2l2P-4rs_display: flex;
  --mixin-ac2l2P-4rs_flex-direction: column;
  --mixin-ac2l2P-4rs_align-items: stretch;
  --mixin-ac2l2P-4rs_justify-content: flex-start;
  --mixin-ac2l2P-4rs_list-style-position: outside;
  --mixin-ac2l2P-4rs_padding-left: 40px;
  --mixin-ac2l2P-4rs_position: relative;
  --mixin-ac2l2P-4rs_list-style-type: disc;
  --mixin-ac2l2P-4rs_white-space: pre-wrap;
}

:where(.all) {
  display: block;
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  position: relative;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}
:where(.__wab_expr_html_text *) {
  white-space: inherit;
  grid-row: auto;
  grid-column: auto;
  background: none;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: none;
  box-sizing: border-box;
  text-decoration-line: none;
  margin: 0;
  border-width: 0px;
}

:where(.img) {
  display: inline-block;
}
:where(.__wab_expr_html_text img) {
  white-space: inherit;
}

:where(.li) {
  display: list-item;
}
:where(.__wab_expr_html_text li) {
  white-space: inherit;
}

:where(.span) {
  display: inline;
  position: static;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text span) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.input) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text input) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: linear-gradient(#ffffff, #ffffff);
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.textarea) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text textarea) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  padding: 2px;
  border: 1px solid lightgray;
}

:where(.button) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}
:where(.__wab_expr_html_text button) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
  background-image: none;
  align-items: flex-start;
  text-align: center;
  padding: 2px 6px;
  border: 1px solid lightgray;
}

:where(.code) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text code) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.pre) {
  font-family: inherit;
  line-height: inherit;
}
:where(.__wab_expr_html_text pre) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
}

:where(.p) {
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}
:where(.__wab_expr_html_text p) {
  white-space: inherit;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  color: inherit;
  text-transform: inherit;
}

:where(.h1) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h1) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h2) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h2) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h3) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h3) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h4) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h4) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h5) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h5) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.h6) {
  font-size: inherit;
  font-weight: inherit;
}
:where(.__wab_expr_html_text h6) {
  white-space: inherit;
  font-size: inherit;
  font-weight: inherit;
}

:where(.address) {
  font-style: inherit;
}
:where(.__wab_expr_html_text address) {
  white-space: inherit;
  font-style: inherit;
}

:where(.a) {
  color: inherit;
}
:where(.__wab_expr_html_text a) {
  white-space: inherit;
  color: inherit;
}

:where(.ol) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ol) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.ul) {
  list-style-type: none;
  padding: 0;
}
:where(.__wab_expr_html_text ul) {
  white-space: inherit;
  list-style-type: none;
  padding: 0;
}

:where(.select) {
  padding: 2px 6px;
}
:where(.__wab_expr_html_text select) {
  white-space: inherit;
  padding: 2px 6px;
}

.plasmic_default__component_wrapper {
  display: grid;
}
.plasmic_default__inline {
  display: inline;
}
.plasmic_page_wrapper {
  display: flex;
  width: 100%;
  min-height: 100vh;
  align-items: stretch;
  align-self: start;
}
.plasmic_page_wrapper > * {
  height: auto !important;
}
.__wab_expr_html_text {
  white-space: normal;
}
.root_reset {
  font-family: var(--mixin-X00bFdoWre_font-family);
  font-size: var(--mixin-X00bFdoWre_font-size);
  font-weight: var(--mixin-X00bFdoWre_font-weight);
  font-style: var(--mixin-X00bFdoWre_font-style);
  color: var(--mixin-X00bFdoWre_color);
  text-align: var(--mixin-X00bFdoWre_text-align);
  text-transform: var(--mixin-X00bFdoWre_text-transform);
  line-height: var(--mixin-X00bFdoWre_line-height);
  letter-spacing: var(--mixin-X00bFdoWre_letter-spacing);
  white-space: var(--mixin-X00bFdoWre_white-space);
}

:where(.root_reset .plasmic_default__h4),
:where(.root_reset .h4),
:where(.root_reset :global(.__wab_expr_html_text) h4),
:where(.root_reset.plasmic_default__h4) {
  font-family: var(--mixin-vWv7grl6YD_font-family);
  color: var(--mixin-vWv7grl6YD_color);
  font-size: var(--mixin-vWv7grl6YD_font-size);
  font-weight: var(--mixin-vWv7grl6YD_font-weight);
  letter-spacing: var(--mixin-vWv7grl6YD_letter-spacing);
  line-height: var(--mixin-vWv7grl6YD_line-height);
}

:where(.root_reset .plasmic_default__h5),
:where(.root_reset .h5),
:where(.root_reset :global(.__wab_expr_html_text) h5),
:where(.root_reset.plasmic_default__h5) {
  font-family: var(--mixin-iNbojl0nhI_font-family);
  color: var(--mixin-iNbojl0nhI_color);
  font-size: var(--mixin-iNbojl0nhI_font-size);
  font-weight: var(--mixin-iNbojl0nhI_font-weight);
  letter-spacing: var(--mixin-iNbojl0nhI_letter-spacing);
  line-height: var(--mixin-iNbojl0nhI_line-height);
}

:where(.root_reset .plasmic_default__h6),
:where(.root_reset .h6),
:where(.root_reset :global(.__wab_expr_html_text) h6),
:where(.root_reset.plasmic_default__h6) {
  font-family: var(--mixin-wy-2o8AKq-_font-family);
  color: var(--mixin-wy-2o8AKq-_color);
  font-size: var(--mixin-wy-2o8AKq-_font-size);
  font-weight: var(--mixin-wy-2o8AKq-_font-weight);
  line-height: var(--mixin-wy-2o8AKq-_line-height);
}

:where(.root_reset .plasmic_default__a),
:where(.root_reset .a),
:where(.root_reset :global(.__wab_expr_html_text) a),
:where(.root_reset.plasmic_default__a) {
  color: var(--mixin-tVe6SYYSop_color);
}

:where(.root_reset .plasmic_default__blockquote),
:where(.root_reset .blockquote),
:where(.root_reset :global(.__wab_expr_html_text) blockquote),
:where(.root_reset.plasmic_default__blockquote) {
  color: var(--mixin-ZnKXQ8mijo_color);
  padding-left: var(--mixin-ZnKXQ8mijo_padding-left);
  border-left: var(--mixin-ZnKXQ8mijo_border-left-width)
    var(--mixin-ZnKXQ8mijo_border-left-style)
    var(--mixin-ZnKXQ8mijo_border-left-color);
}

:where(.root_reset .plasmic_default__h1),
:where(.root_reset .h1),
:where(.root_reset :global(.__wab_expr_html_text) h1),
:where(.root_reset.plasmic_default__h1) {
  font-family: var(--mixin-tiKRlyKiB_font-family);
  color: var(--mixin-tiKRlyKiB_color);
  font-size: var(--mixin-tiKRlyKiB_font-size);
  font-weight: var(--mixin-tiKRlyKiB_font-weight);
  letter-spacing: var(--mixin-tiKRlyKiB_letter-spacing);
  line-height: var(--mixin-tiKRlyKiB_line-height);
}

:where(.root_reset .plasmic_default__h2),
:where(.root_reset .h2),
:where(.root_reset :global(.__wab_expr_html_text) h2),
:where(.root_reset.plasmic_default__h2) {
  font-family: var(--mixin-70DPORDir-_font-family);
  color: var(--mixin-70DPORDir-_color);
  font-size: var(--mixin-70DPORDir-_font-size);
  font-weight: var(--mixin-70DPORDir-_font-weight);
  letter-spacing: var(--mixin-70DPORDir-_letter-spacing);
  line-height: var(--mixin-70DPORDir-_line-height);
}

:where(.root_reset .plasmic_default__h3),
:where(.root_reset .h3),
:where(.root_reset :global(.__wab_expr_html_text) h3),
:where(.root_reset.plasmic_default__h3) {
  font-family: var(--mixin-VLfLpuOl-T_font-family);
  color: var(--mixin-VLfLpuOl-T_color);
  font-size: var(--mixin-VLfLpuOl-T_font-size);
  font-weight: var(--mixin-VLfLpuOl-T_font-weight);
  letter-spacing: var(--mixin-VLfLpuOl-T_letter-spacing);
  line-height: var(--mixin-VLfLpuOl-T_line-height);
}

:where(.root_reset .plasmic_default__a:hover),
:where(.root_reset .a:hover),
:where(.root_reset :global(.__wab_expr_html_text) a:hover),
:where(.root_reset.plasmic_default__a:hover) {
  color: var(--mixin-aSDICzgaZE_color);
}

:where(.root_reset .plasmic_default__code),
:where(.root_reset .code),
:where(.root_reset :global(.__wab_expr_html_text) code),
:where(.root_reset.plasmic_default__code) {
  background: #f8f8f8;
  font-family: var(--mixin-yipuD8O4E8_font-family);
  border-radius: var(--mixin-yipuD8O4E8_border-top-left-radius)
    var(--mixin-yipuD8O4E8_border-top-right-radius)
    var(--mixin-yipuD8O4E8_border-bottom-right-radius)
    var(--mixin-yipuD8O4E8_border-bottom-left-radius);
  padding: var(--mixin-yipuD8O4E8_padding-top)
    var(--mixin-yipuD8O4E8_padding-right) var(--mixin-yipuD8O4E8_padding-bottom)
    var(--mixin-yipuD8O4E8_padding-left);
  border-top: var(--mixin-yipuD8O4E8_border-top-width)
    var(--mixin-yipuD8O4E8_border-top-style)
    var(--mixin-yipuD8O4E8_border-top-color);
  border-right: var(--mixin-yipuD8O4E8_border-right-width)
    var(--mixin-yipuD8O4E8_border-right-style)
    var(--mixin-yipuD8O4E8_border-right-color);
  border-bottom: var(--mixin-yipuD8O4E8_border-bottom-width)
    var(--mixin-yipuD8O4E8_border-bottom-style)
    var(--mixin-yipuD8O4E8_border-bottom-color);
  border-left: var(--mixin-yipuD8O4E8_border-left-width)
    var(--mixin-yipuD8O4E8_border-left-style)
    var(--mixin-yipuD8O4E8_border-left-color);
}

:where(.root_reset .plasmic_default__pre),
:where(.root_reset .pre),
:where(.root_reset :global(.__wab_expr_html_text) pre),
:where(.root_reset.plasmic_default__pre) {
  background: #f8f8f8;
  font-family: var(--mixin-RoQWLylCHO_font-family);
  border-radius: var(--mixin-RoQWLylCHO_border-top-left-radius)
    var(--mixin-RoQWLylCHO_border-top-right-radius)
    var(--mixin-RoQWLylCHO_border-bottom-right-radius)
    var(--mixin-RoQWLylCHO_border-bottom-left-radius);
  padding: var(--mixin-RoQWLylCHO_padding-top)
    var(--mixin-RoQWLylCHO_padding-right) var(--mixin-RoQWLylCHO_padding-bottom)
    var(--mixin-RoQWLylCHO_padding-left);
  border-top: var(--mixin-RoQWLylCHO_border-top-width)
    var(--mixin-RoQWLylCHO_border-top-style)
    var(--mixin-RoQWLylCHO_border-top-color);
  border-right: var(--mixin-RoQWLylCHO_border-right-width)
    var(--mixin-RoQWLylCHO_border-right-style)
    var(--mixin-RoQWLylCHO_border-right-color);
  border-bottom: var(--mixin-RoQWLylCHO_border-bottom-width)
    var(--mixin-RoQWLylCHO_border-bottom-style)
    var(--mixin-RoQWLylCHO_border-bottom-color);
  border-left: var(--mixin-RoQWLylCHO_border-left-width)
    var(--mixin-RoQWLylCHO_border-left-style)
    var(--mixin-RoQWLylCHO_border-left-color);
}

:where(.root_reset .plasmic_default__ol),
:where(.root_reset .ol),
:where(.root_reset :global(.__wab_expr_html_text) ol),
:where(.root_reset.plasmic_default__ol) {
  display: var(--mixin-uWGc3Qwcpc_display);
  flex-direction: var(--mixin-uWGc3Qwcpc_flex-direction);
  align-items: var(--mixin-uWGc3Qwcpc_align-items);
  justify-content: var(--mixin-uWGc3Qwcpc_justify-content);
  list-style-position: var(--mixin-uWGc3Qwcpc_list-style-position);
  padding-left: var(--mixin-uWGc3Qwcpc_padding-left);
  position: var(--mixin-uWGc3Qwcpc_position);
  list-style-type: var(--mixin-uWGc3Qwcpc_list-style-type);
  flex-column-gap: var(--mixin-uWGc3Qwcpc_flex-column-gap);
}

:where(.root_reset .plasmic_default__ul),
:where(.root_reset .ul),
:where(.root_reset :global(.__wab_expr_html_text) ul),
:where(.root_reset.plasmic_default__ul) {
  display: var(--mixin-ac2l2P-4rs_display);
  flex-direction: var(--mixin-ac2l2P-4rs_flex-direction);
  align-items: var(--mixin-ac2l2P-4rs_align-items);
  justify-content: var(--mixin-ac2l2P-4rs_justify-content);
  list-style-position: var(--mixin-ac2l2P-4rs_list-style-position);
  padding-left: var(--mixin-ac2l2P-4rs_padding-left);
  position: var(--mixin-ac2l2P-4rs_position);
  list-style-type: var(--mixin-ac2l2P-4rs_list-style-type);
  flex-column-gap: var(--mixin-ac2l2P-4rs_flex-column-gap);
}
