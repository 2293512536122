.root {
  flex-direction: row;
  display: inline-flex;
  position: relative;
  width: auto;
  height: auto;
}
.root > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: calc(0px - 20px);
  width: calc(100% + 20px);
}
.root > :global(.__wab_flex-container) > *,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.root > :global(.__wab_flex-container) > picture > img,
.root > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-left: 20px;
}
.search:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
  height: 35px;
}
.svg__mexap {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.svg__aMpD {
  position: relative;
  object-fit: cover;
  width: 1em;
  height: 1em;
}
.hidePasted:global(.__wab_instance) {
  max-width: 100%;
  position: relative;
}
.text {
  color: #ffffff;
}
