.root {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100vh;
  justify-content: flex-start;
  align-items: center;
  background: var(--token-BRpR8JzSS);
  overflow: auto;
  min-width: 0;
}
.freeBox__clLf9 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 800px;
  min-width: 0;
  padding: 8px;
}
.frame5 {
  flex-direction: column;
  width: 277px;
  height: auto;
  display: flex;
  overflow: hidden;
}
.frame5 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame5 > :global(.__wab_flex-container) > *,
.frame5 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame5 > :global(.__wab_flex-container) > picture > img,
.frame5
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.img {
  position: relative;
  object-fit: cover;
  max-width: 100%;
}
.img > picture > img {
  object-fit: cover;
}
.img > :global(.__wab_img-spacer) > img {
  object-fit: cover;
}
.freeBox___3JCkx {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 800px;
  min-width: 0;
  min-height: 0;
  padding: 24px;
}
.freeBox__fVjif {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.freeBox__fVjif > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.freeBox__fVjif > :global(.__wab_flex-container) > *,
.freeBox__fVjif > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.freeBox__fVjif > :global(.__wab_flex-container) > picture > img,
.freeBox__fVjif
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.frame33 {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  min-width: 0;
}
.frame33 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10px);
  height: calc(100% + 10px);
}
.frame33 > :global(.__wab_flex-container) > *,
.frame33 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame33 > :global(.__wab_flex-container) > picture > img,
.frame33
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10px;
}
.text___3FOoe {
  display: block;
  font-size: 22px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.freeBox__gkogO {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  background: #ffffff;
  min-width: 0;
  padding: 0px;
}
.svg__dnghY {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 100%;
  height: 1px;
  display: block;
  min-width: 0;
  flex-shrink: 0;
}
.freeBox__vlX6U {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  background: #ffffff;
  min-width: 0;
  border-radius: 6px;
  padding: 20px;
  margin: 12px 0px;
}
.image {
  object-fit: contain;
  max-width: 70%;
  width: 150px;
  height: 100px;
  flex-shrink: 0;
}
.image > picture > img {
  object-fit: contain;
}
.image > :global(.__wab_img-spacer) > img {
  object-fit: contain;
}
.numberTxt {
  position: relative;
  width: 100%;
  height: auto;
  max-width: 800px;
  padding-bottom: 0px;
  color: #000000;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  font-weight: 700;
  min-width: 0;
}
.orderInfo {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  min-width: 0;
}
.orderInfo > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  min-width: 0;
  margin-top: calc(0px - 24px);
  height: calc(100% + 24px);
}
.orderInfo > :global(.__wab_flex-container) > *,
.orderInfo > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.orderInfo > :global(.__wab_flex-container) > picture > img,
.orderInfo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 24px;
}
.frame15 {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.frame15 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 12px);
  height: calc(100% + 12px);
}
.frame15 > :global(.__wab_flex-container) > *,
.frame15 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame15 > :global(.__wab_flex-container) > picture > img,
.frame15
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 12px;
}
.text__kg0VX {
  display: block;
  font-size: 12px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.frame14 {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  min-width: 0;
}
.frame14 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.frame14 > :global(.__wab_flex-container) > *,
.frame14 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.frame14 > :global(.__wab_flex-container) > picture > img,
.frame14
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.date {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.items {
  flex-direction: column;
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.items > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - 30px);
  height: calc(100% + 30px);
}
.items > :global(.__wab_flex-container) > *,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items > :global(.__wab_flex-container) > picture > img,
.items > :global(.__wab_flex-container) > :global(.__wab_slot) > picture > img {
  margin-top: 30px;
}
.freeBox___12Yab {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  max-width: 100%;
  align-self: stretch;
  background: #ffffff;
  min-width: 0;
  padding: 0px;
}
.svg__rurPp {
  object-fit: cover;
  max-width: 100%;
  color: #fff;
  width: 100%;
  height: 1px;
  display: block;
  min-width: 0;
  flex-shrink: 0;
}
.servicesWrapper2 {
  flex-direction: row;
  height: auto;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
}
.servicesWrapper2 > :global(.__wab_flex-container) {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 0;
  margin-left: calc(0px - 12px);
  width: calc(100% + 12px);
}
.servicesWrapper2 > :global(.__wab_flex-container) > *,
.servicesWrapper2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.servicesWrapper2 > :global(.__wab_flex-container) > picture > img,
.servicesWrapper2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 12px;
}
.serviceTitle2 {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.serviceTitle2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.serviceTitle2 > :global(.__wab_flex-container) > *,
.serviceTitle2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.serviceTitle2 > :global(.__wab_flex-container) > picture > img,
.serviceTitle2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.text__amSEs {
  display: block;
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
}
.items2 {
  flex-direction: column;
  width: auto;
  height: auto;
  display: flex;
  position: relative;
}
.items2 > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: calc(0px - 10.758px);
  height: calc(100% + 10.758px);
}
.items2 > :global(.__wab_flex-container) > *,
.items2 > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.items2 > :global(.__wab_flex-container) > picture > img,
.items2
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: 10.758px;
}
.totalPrice {
  display: block;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  line-height: 1;
  color: rgba(255, 255, 255, 1);
  position: relative;
  font-weight: 700;
}
